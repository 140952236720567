import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

import { loadStatuses } from 'actions/statuses';
import FormLink from 'helpers/FormLink';
import { allCommunitiesPremium } from 'helpers/communities';
import FlashMessage from 'components/shared/FlashMessage';
import CommunityCard from 'components/communities/CommunityCard';
import Status from 'components/communities/Status';
import CommunityRepList from 'components/communities/CommunityRepList';
import HintCard from 'components/community_reps/HintCard';
import CompleteCompanyProfile from './shared/CompleteCompanyProfile';

export default function CommunityRepDashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const communities = useSelector((state) => state.communities);
  const statuses = useSelector((state) => _.keyBy(state.statuses, "id"));

  const sortedCommunities = useMemo(() =>
     _.sortBy(communities, "name"), [communities]
  );
  const firstCommunityId = _.get(sortedCommunities, "0.id");

  useEffect(() => {
    dispatch(loadStatuses());
  }, []);

  return (
    <div className="page-container">
      <FlashMessage />
      <HintCard firstCommunityId={firstCommunityId}/>
      {allCommunitiesPremium(communities) ? (
        <CompleteCompanyProfile />
      ) : (
        <div className="card ad-card updates-link">
          <h4 className="updates-caption">Reach More Agents with Connect+</h4>
          <span>&bull; Share availability, specials & more</span>
          <span>&bull; Get notified when agent view your updates</span>
          <span>&bull; Meet new agents in your area</span>
          <FormLink className="button" name={user.full_name} email={user.email} link="register">
            Register for Connect+
          </FormLink>
          <Link to="/" className="updates-link-text">
            Learn More About Connect+
          </Link>
        </div>
      )}

      {sortedCommunities.map((community) => (
        <CommunityCard key={community.id} community={community} show_missing_info>
          <div className="community-nav-display">
            <Link to="/conversations">
              <i className="icon-mail-alt" /> Messages
            </Link>
            <Link
              className="community-nav updates-link-text"
              to={`/communities/${community.id}/followers`}
            >
              <i className="icon-wifi" />
              {pluralize("Followers", community.followers.length, true)}
            </Link>
            <Link to={`/communities/${community.id}/edit`}>
              <p className="views-text"><i className="icon-pencil" title="Edit"/> Profile</p>
            </Link>
          </div>
          <CommunityRepList community={community} />
          <div className="card-section">
            {statuses[community.status?.id] ? (
              <Status status={community.status} views={statuses[community.status.id]?.views}/>
            ) : (
              <div className="card-extra-container">
                <Link className="button update" to="/statuses/update">Post Update</Link>
                <div className="updates-button-helper-text">Share Availability, Specials, + More</div>
              </div>
            )}
          </div>
        </CommunityCard>
      ))}
    </div>
  );
}
