export const HighlightText = ({text='', highlight=''}) => {
  if (!highlight.trim()) return <>{text}</>;

  const regex = new RegExp(`(${_.escapeRegExp(highlight.trim())})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, i) => (
        regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
      ))}
    </>
  );
};
