import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: process.env.GOOGLE_MAPS_API_KEY,
  version: "3.47",
  libraries: ["places", "geometry"]
});

const loadedLoader = loader.load();

export const places = (() => {
  let api;

  return () => {
    return api || (api = init());
  };

  function init() {
    return loadedLoader.then((google) => {
      return google.maps.places;
    })
    .catch(e => {
      console.error("google loader fail", e)
    });
  }
})();

export const maps = (() => {
  let api;

  return () => {
    return api || (api = init());
  };

  function init() {
    return loadedLoader.then((google) => {
      return google.maps;
    })
    .catch(e => {
      console.error("google loader fail", e)
    });
  }
})();

export const isLoaded = (() => {
  let isLoaded = false;

  loadedLoader.then(() => isLoaded = true)

  return () => isLoaded;
})();
