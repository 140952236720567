import { useDispatch } from 'react-redux';
import { stopPropagation } from 'helpers/events';
import { enterConversation } from 'helpers/enterConversation';
import { followCommunity, unfollowCommunity } from 'actions/communities';

export default function CommunityActions({community, withinProfile}) {
  const dispatch = useDispatch();
  return (
    <div className="card-actions">
      <button type="button" onClick={() => enterConversation(community.id, false, dispatch)}>
      <i className="icon-mail-alt" aria-label="send message" /> Message
      </button>
      <button type="button" onClick={stopPropagation(() => {
        dispatch(community.followed ? unfollowCommunity(community) : followCommunity(community))
      })}>
        {community.followed ? '- Unfollow' : '+ Follow'}
      </button>
      {!withinProfile &&
        <a href={`communities/${community.id}`} target="_blank" className="button">
        <i className="icon-user" aria-label="profile" /> Profile
        </a>
      }
    </div>
  );
}
