import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { preventDefault } from 'helpers/events';
import useErrors, { Errors } from 'helpers/errors';
import { CARE_LEVELS, ROOM_TYPES, getRoomType, CARE_LEVEL_QUESTIONS } from 'helpers/communities';
import { set, omit } from 'lodash/fp';

import { updateCommunities } from 'actions/communities'
import { CoverPhotoInput } from 'components/shared/ImageInput';
import Loading from 'components/shared/Loading';
import separateCareLevels from 'helpers/separateCareLevels';
import classnames from 'classnames';

const STRING_FIELDS = ['phone', 'website', 'description'];
const trimStringFields = (profile) => _.mapValues(_.pick(profile, STRING_FIELDS), _.trim);
const normalizeDetails = (community) => ({
  ..._.pick(community, 'id', 'care_levels', 'cover_photo_crop', 'room_types'),
  ...trimStringFields(community),
  cover_photo: community?.cover_photo?.signed_id,
  cover_photo_url: community?.cover_photo?.url || community?.photo,
  photo: null,
  accepts_medicaid: community?.accepts_medicaid,
  accepts_innovage: community?.accepts_innovage,
  accepts_altcs_banner: community?.accepts_altcs_banner,
  accepts_altcs_mercy: community?.accepts_altcs_mercy,
  accepts_altcs_united: community?.accepts_altcs_united,
  res_has_community_fee: community?.res_has_community_fee,
  res_community_fee: community?.res_community_fee,
  res_has_deposit: community?.res_has_deposit,
  res_deposit: community?.res_deposit,
  res_all_inclusive_pricing: community?.res_all_inclusive_pricing,
  res_comments_pricing: community?.res_comments_pricing,
  res_comments_other: community?.res_comments_other,
  res_respite: community?.res_respite,
  res_pets: community?.res_pets,
  res_awake_staff: community?.res_awake_staff,
  res_smoking: community?.res_smoking,
  res_nurse_on_staff: community?.res_nurse_on_staff,
  res_two_person_transfer: community?.res_two_person_transfer,
  res_hoyer_lift: community?.res_hoyer_lift,
  il_has_community_fee: community?.il_has_community_fee,
  il_community_fee: community?.il_community_fee,
  il_has_deposit: community?.il_has_deposit,
  il_deposit: community?.il_deposit,
  il_all_inclusive_pricing: community?.il_all_inclusive_pricing,
  il_comments_pricing: community?.il_comments_pricing,
  il_comments_other: community?.il_comments_other,
  al_has_community_fee: community?.al_has_community_fee,
  al_community_fee: community?.al_community_fee,
  al_has_deposit: community?.al_has_deposit,
  al_deposit: community?.al_deposit,
  al_all_inclusive_pricing: community?.al_all_inclusive_pricing,
  al_comments_pricing: community?.al_comments_pricing,
  al_comments_other: community?.al_comments_other,
  al_respite: community?.al_respite,
  al_pets: community?.al_pets,
  al_awake_staff: community?.al_awake_staff,
  al_smoking: community?.al_smoking,
  al_nurse_on_staff: community?.al_nurse_on_staff,
  al_two_person_transfer: community?.al_two_person_transfer,
  al_hoyer_lift: community?.al_hoyer_lift,
  mc_has_community_fee: community?.mc_has_community_fee,
  mc_community_fee: community?.mc_community_fee,
  mc_has_deposit: community?.mc_has_deposit,
  mc_deposit: community?.mc_deposit,
  mc_all_inclusive_pricing: community?.mc_all_inclusive_pricing,
  mc_comments_pricing: community?.mc_comments_pricing,
  mc_comments_other: community?.mc_comments_other,
  mc_respite: community?.mc_respite,
  mc_secured_environment: community?.mc_secured_environment,
  mc_two_person_transfer: community?.mc_two_person_transfer,
  mc_hoyer_lift: community?.mc_hoyer_lift,
  sn_community_fee: community?.sn_community_fee,
  sn_deposit: community?.sn_deposit
});

const validateRoomTypePricesPresent = (communityProfile, addErrors) => {
  _.each(communityProfile?.room_types, (care_level_rooms, care_level) => {
    _.each(care_level_rooms, (room_details, room_type) => {
      if (! room_details?.price?.length || !_.compact(room_details?.price)[0] ) {
        addErrors('room_types', `must each have a price specified if selected (${care_level}: ${room_type})`);
      }
    });
  });
};

export default function CommunityDetailsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const community = useSelector((state) =>  _.find(state.communities, {id: Number(id)}))
  const [communityProfile, setCommunityProfile] = useState(normalizeDetails(community));
  const [loading, setLoading] = useState(false);

  const careLevels = _.get(communityProfile, "care_levels", []);
  const humanCareLevels = _.map(careLevels, (level) => level.toUpperCase());
  const details = _.get(communityProfile, "room_types", {});

  const [errors, valid] = useErrors([validateRoomTypePricesPresent]);

  useEffect(() => {
    setCommunityProfile(normalizeDetails(community))
  }, [community]);

  const updateProfile = (name) => (e) => {

    let payload = _.get(e, 'target.value', e);

    if (e && e.target && e.target.type === 'number') {
      payload = parseInt(payload);
    };

    if (e && e.target && e.target.type === 'boolean') {
      payload = Boolean(payload);
    };

    setCommunityProfile((profile) => ({...profile, [name]: payload}));
  };

  const updateCommunity = (to='/') => async () => {
    if (!valid(communityProfile)) {
      return;
    }
    await dispatch(updateCommunities({communities: [communityProfile]}));
    navigate(to, {state: {success: "Profile Updated"}});
  };

  if (!communityProfile.id) return <Loading />;

  const updateRooms = (careLevel) => ({target: {value:room, checked}}) => {
    setCommunityProfile((community) => {
      if (checked) {
        return set(['room_types', careLevel, room, 'price', 0], "", community);
      } else {
        return omit([['room_types', careLevel, room]], community);
      }
    });
  }

  const updatePrices = (careLevel, room) => ({target: {value}}) => {
    setCommunityProfile(set(['room_types', careLevel, room, 'price', 0], value));
  }

  return (
    <div className="page-container">
      <div className="card hint-card">
        <span className="hint-caption">Manage Community Settings</span>
        <span className="hint-text">
          Visit <Link className="link-text" to="/communities/edit">Community Settings</Link> to update name, address, care levels, and more.
        </span>
      </div>
      <div className="card">
        <div className="card-details form-card">
          <h3 className='title'>{community.name}</h3>
          <p><span>{community.address},</span> <span>{community.city}, {community.state} {community.zip_code}</span></p>
          <p>
            {community.capacity && (
              <span className="card-details-stat">{community.capacity} beds</span>
            )} | {community.care_levels && (
              <span className="card-details-stat">{separateCareLevels(community.care_levels)}</span>
            )}
          </p>
        </div>
        <form onSubmit={preventDefault(updateCommunity)}>
          <div className="form-container">
            <h3 className="community-section-header">
              General
            </h3>
            <hr/>
            <div className="basic-form-field">
              <CoverPhotoInput
                needsUpgrade={communityProfile.cover_photo_url && !communityProfile.cover_photo}
                initialImage={communityProfile.cover_photo_url}
                crop={communityProfile.cover_photo_crop}
                onImageChange={updateProfile("cover_photo")}
                onCropChange={updateProfile("cover_photo_crop")}
                loading={loading}
                setLoading={setLoading}/>
            </div>
            <div className="basic-form-field">
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                className="input-phone"
                value={communityProfile.phone}
                onChange={updateProfile("phone")}
              />
              <input
                name="website"
                placeholder="Website"
                className="input-website"
                value={communityProfile.website}
                onChange={updateProfile("website")}
              />
            </div>
            <div className="community-form-question">
              <b>Description</b>
              <textarea
                rows="3"
                name="description"
                placeholder="Description"
                className="input-description"
                value={communityProfile.description}
                onChange={updateProfile("description")}
              />
            </div>

            
            <div className="community-form-question">
              <b>Accepts Medicaid</b>
              <label className="checkbox-row">
                  <input 
                    type="radio"
                    name="accepts_medicaid"
                    value="yes"
                    checked={communityProfile.accepts_medicaid == 'yes' }
                    onChange={updateProfile("accepts_medicaid")}
                  />
                  <span className="checkable">Yes</span>
              </label>
              <label className="checkbox-row">
                  <input 
                    type="radio"
                    name="accepts_medicaid"
                    value="no"
                    checked={communityProfile.accepts_medicaid == 'no' }
                    onChange={updateProfile("accepts_medicaid")}
                  />
                  <span className="checkable">No</span>
              </label>
              <label className="checkbox-row">
                  <input 
                    type="radio"
                    name="accepts_medicaid"
                    value="sometimes"
                    checked={communityProfile.accepts_medicaid == 'sometimes' }
                    onChange={updateProfile("accepts_medicaid")}
                  />
                  <span className="checkable">Sometimes - Please Inquire</span>
              </label>
            </div>
          

            { community.state === 'CO' &&
              <div>
                <div className="community-form-question">
                  <b>Accepts InnovAge</b>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_innovage"
                        value="yes"
                        checked={communityProfile.accepts_innovage == 'yes' }
                        onChange={updateProfile("accepts_innovage")}
                      />
                      <span className="checkable">Yes</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_innovage"
                        value="no"
                        checked={communityProfile.accepts_innovage == 'no' }
                        onChange={updateProfile("accepts_innovage")}
                      />
                      <span className="checkable">No</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_innovage"
                        value="sometimes"
                        checked={communityProfile.accepts_innovage == 'sometimes' }
                        onChange={updateProfile("accepts_innovage")}
                      />
                      <span className="checkable">Sometimes - Please Inquire</span>
                  </label>
                </div>
              </div>
            }

            { community.state === 'AZ' &&
              <div>
                <div className="community-form-question">
                  <b>Accepts ALTCS Banner</b>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_banner"
                        value="yes"
                        checked={communityProfile.accepts_altcs_banner == 'yes' }
                        onChange={updateProfile("accepts_altcs_banner")}
                      />
                      <span className="checkable">Yes</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_banner"
                        value="no"
                        checked={communityProfile.accepts_altcs_banner == 'no' }
                        onChange={updateProfile("accepts_altcs_banner")}
                      />
                      <span className="checkable">No</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_banner"
                        value="sometimes"
                        checked={communityProfile.accepts_altcs_banner == 'sometimes' }
                        onChange={updateProfile("accepts_altcs_banner")}
                      />
                      <span className="checkable">Sometimes - Please Inquire</span>
                  </label>
                </div>
                <div className="community-form-question">
                  <b>Accepts ALTCs Mercy</b>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_mercy"
                        value="yes"
                        checked={communityProfile.accepts_altcs_mercy == 'yes' }
                        onChange={updateProfile("accepts_altcs_mercy")}
                      />
                      <span className="checkable">Yes</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_mercy"
                        value="no"
                        checked={communityProfile.accepts_altcs_mercy == 'no' }
                        onChange={updateProfile("accepts_altcs_mercy")}
                      />
                      <span className="checkable">No</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_mercy"
                        value="sometimes"
                        checked={communityProfile.accepts_altcs_mercy == 'sometimes' }
                        onChange={updateProfile("accepts_altcs_mercy")}
                      />
                      <span className="checkable">Sometimes - Please Inquire</span>
                  </label>
                </div>
                <div className="community-form-question">
                  <b>Accepts ALTCs United</b>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_united"
                        value="yes"
                        checked={communityProfile.accepts_altcs_united == 'yes' }
                        onChange={updateProfile("accepts_altcs_united")}
                      />
                      <span className="checkable">Yes</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_united"
                        value="no"
                        checked={communityProfile.accepts_altcs_united == 'no' }
                        onChange={updateProfile("accepts_altcs_united")}
                      />
                      <span className="checkable">No</span>
                  </label>
                  <label className="checkbox-row">
                      <input 
                        type="radio"
                        name="accepts_altcs_united"
                        value="sometimes"
                        checked={communityProfile.accepts_altcs_united == 'sometimes' }
                        onChange={updateProfile("accepts_altcs_united")}
                      />
                      <span className="checkable">Sometimes - Please Inquire</span>
                  </label>
                </div>
              </div>
            }
          </div>
          

          <div className="form-container">
            {_.map(careLevels, (careLevel) => (
              <div key={CARE_LEVELS[careLevel]} className="care-level-container">
                <h3 className="community-section-header">
                  {CARE_LEVELS[careLevel] || "Room Types & Starting Prices"}
                </h3>
                <hr/>
                
                <div className="community-form-question">
                  <div><b>All-Inclusive Pricing</b></div>
                  <div>
                    <label className="checkbox-row">
                        <input 
                          type="radio"
                          name={ careLevel + "_all_inclusive_pricing" }
                          value="yes"
                          checked={communityProfile[careLevel + "_all_inclusive_pricing"] == 'yes' }
                          onChange={updateProfile(careLevel + "_all_inclusive_pricing")}
                        />
                        <span className="checkable">Yes</span>
                    </label>
                    <label className="checkbox-row">
                        <input 
                          type="radio"
                          name={ careLevel + "_all_inclusive_pricing" }
                          value="no"
                          checked={communityProfile[careLevel + "_all_inclusive_pricing"] == 'no' }
                          onChange={updateProfile(careLevel + "_all_inclusive_pricing")}
                        />
                        <span className="checkable">No</span>
                    </label>
                    <label className="checkbox-row">
                        <input 
                          type="radio"
                          name={ careLevel + "_all_inclusive_pricing" }
                          value="sometimes"
                          checked={ communityProfile[careLevel + "_all_inclusive_pricing"] == 'sometimes' }
                          onChange={updateProfile(careLevel + "_all_inclusive_pricing")}
                        />
                        <span className="checkable">Sometimes - Please Inquire</span>
                    </label>
                  </div>
                </div>

                <div className="community-form-question">
                  <div>
                    <b>Community Fee</b> <p>Numbers only. Enter 0 if not applicable.</p>
                  </div>
                  <div>
                    <input
                      type="number"
                      className={ _.lt(communityProfile[careLevel + "_community_fee"]) ? "needs-info" : "" }
                      placeholder="Community Fee"
                      name={careLevel + "_community_fee"}
                      value={communityProfile[careLevel + "_community_fee"]}
                      onWheel={(e) => e.target.blur()}
                      onChange={updateProfile(careLevel + "_community_fee")} />
                  </div>
                </div>

                <div className="community-form-question">
                  <div>
                    <b>Deposit</b> <p>Numbers only. Enter 0 if not applicable.</p>
                  </div>
                  <div>
                    <input
                      type="number"
                      placeholder="Deposit"
                      className=""
                      name={careLevel + "_deposit"}
                      value={communityProfile[careLevel + "_deposit"]}
                      onWheel={(e) => e.target.blur()}
                      onChange={updateProfile(careLevel + "_deposit")} />
                  </div>
                </div>

                <div><b>Room Types & Starting Prices</b></div>
                <div className="room-type-container">
                  {_.map(ROOM_TYPES[careLevel], (roomType) => (
                    <div key={roomType}>
                      <label className="checkbox-row" key={roomType}>
                        <input
                          type="checkbox"
                          value={roomType}
                          checked={_.has(details, [careLevel, roomType])}
                          onChange={updateRooms(careLevel)} />
                        <span className="checkable">{getRoomType(roomType, {by: 'key'})}</span>
                      </label>

                      <input
                        type="number"
                        placeholder="Starting Price"
                        className={classnames({"needs-info": _.has(details, [careLevel, roomType])})}
                        name={roomType}
                        value={_.get(details, [careLevel, roomType, 'price', 0], "")}
                        onWheel={(e) => e.target.blur()}
                        onChange={updatePrices(careLevel, roomType)} />
                    </div>
                  ))}
                </div>

                <div className="community-form-question">
                  <b>Additional Pricing Information</b>
                  <textarea
                    rows="3"
                    name={ careLevel + "_comments_pricing" }
                    placeholder="Additional Pricing Details"
                    className="input-description"
                    value={communityProfile[careLevel + "_comments_pricing"]}
                    onChange={updateProfile(careLevel + "_comments_pricing")}
                  />
                </div>

                <div>
                  {_.map(CARE_LEVEL_QUESTIONS[careLevel], (question) => (
                    <div key={question} className="community-form-question">
                      <b>{ _.startCase(question) }</b>
                      <label className="checkbox-row">
                          <input 
                            type="radio"
                            name={careLevel + "_" + question}
                            value="yes"
                            checked={communityProfile[careLevel + "_" + question] == 'yes' }
                            onChange={updateProfile(careLevel + "_" + question)}
                          />
                          <span className="checkable">Yes</span>
                      </label>
                      <label className="checkbox-row">
                          <input 
                            type="radio"
                            name={careLevel + "_" + question}
                            value="no"
                            checked={communityProfile[careLevel + "_" + question] == 'no' }
                            onChange={updateProfile(careLevel + "_" + question)}
                          />
                          <span className="checkable">No</span>
                      </label>
                      <label className="checkbox-row">
                          <input 
                            type="radio"
                            name={careLevel + "_" + question}
                            value="sometimes"
                            checked={communityProfile[careLevel + "_" + question] == 'sometimes' }
                            onChange={updateProfile(careLevel + "_" + question)}
                          />
                          <span className="checkable">Sometimes - Please Inquire</span>
                      </label>
                    </div>
                  ))}
                </div>

                <div className="community-form-question">
                  <b>Additional Information</b>
                  <textarea
                    rows="3"
                    name={ careLevel + "_comments_other" }
                    placeholder="Additional Information"
                    className="input-description"
                    value={communityProfile[careLevel + "_comments_other"]}
                    onChange={updateProfile(careLevel + "_comments_other")}
                  />
                </div>

              </div>
            ))}
          </div>
          <Errors errors={errors} />
          <div className="button-group align-center">
            <button
              type="button"
              onClick={updateCommunity(`/`)}
              disabled={loading}
            >Save
            </button>
            <button
              type="button"
              onClick={updateCommunity(`/communities/${community.id}`)}
              disabled={loading}
            >Save and View Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
