export default function userReducer(state=null, action) {
  switch (action.type) {
    case 'user/recieved':
      return action.user;
    case 'user/signedOut':
      return null;
    case 'user/communityProfileUpdated':
      return {
        ...state,
        community_profiles: state.community_profiles.map((community_profile) => {
          if (community_profile.id != action.profile?.id) return community_profile;
          return {...community_profile, ...action.profile};
        })
      };
    default:
      return state;
    }
}
