
import { useState } from "react";

const humanize = (str) => _.chain(str).replace(/_/g, ' ').upperFirst().value();
const addErrors = (errors) => (key, value) => errors.push([key, value]);

export default function useErrors(...validations) {
  validations = _.flatten(validations);
  const [errors, setErrors] = useState();

  const valid = (model) => {
    const errors = [];
    _.each(validations, (v) => v(model, addErrors(errors)));
    setErrors(errors);
    return !errors.length;
  };

  return [errors, valid];
}

export const Errors = ({errors}) => {
  return _.toArray(errors).length > 0 && (
    <div className="form-container">
      <div className="errors">
        Some errors prevented this form from being saved:
        <ul>
          {_.map(errors, ([key, msg]) => (
            <li key={key + msg}>{humanize(key)} {msg}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
