export default function searchReducer(state={}, action) {
  switch (action.type) {
    case 'search/setSelectedCommunityID':
      return {...state,
        selectedCommunityId: action.id,
        searchMode: action.searchMode === undefined ? state.searchMode : action.searchMode
      };
    case 'search/receivedPredictedPlace':
      return {...state, predictedPlace: action.place};
    case 'search/setSelectedLocation':
      const {place, location} = action;
      return {...state, place, location, predictedPlace: null};
    default:
      return state;
    }
}
