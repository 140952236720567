import defaultAvatar from 'images/no-headshot.png';

export default function ProfileImage({user, ...props}) {
  return (
    <img {...props}
      className="profile-image"
      src={user?.profile_image || defaultAvatar}
      title={user?.full_name || "Unknown Contact"}
      alt=""/>
  );
}
