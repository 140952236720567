export const allCommunitiesPremium = (communities) => {
  return _.every(communities, 'premium');
};

export const isResidential = (community) => {
  return _.includes(community?.care_levels, 'res');
};

export const CARE_LEVELS = {
  il: "Independent Living",
  al: "Assisted Living",
  mc: "Memory Care",
  sn: "Skilled Nursing",
  res: "Residential"
};

export const CARE_LEVEL_QUESTIONS = {
  res: [
    'respite',
    'pets',
    'awake_staff',
    'smoking',
    'nurse_on_staff',
    'two_person_transfer',
    'hoyer_lift'
  ],
  il: [],
  al: [
    'respite',
    'pets',
    'awake_staff',
    'smoking',
    'nurse_on_staff',
    'two_person_transfer',
    'hoyer_lift'
  ],
  mc: [
    'respite',
    'secured_environment',
    'two_person_transfer',
    'hoyer_lift'
  ],
  sn: []
};

export const COMMERCIAL_CARE_LEVELS = _.omit(CARE_LEVELS, 'res');

export const ROOM_TYPES = {
  res: [ "shared", "semi-private", "private" ],
  il: [ "studio", "1-bedroom", "2-bedroom"],
  al: [ "shared", "studio", "1-bedroom", "2-bedroom"],
  mc: [ "shared", "private" ],
  sn: [ "semi-private", "private" ]
};

export const ROOM_TYPE_OPTIONS = [
  ["Shared", "shared"],
  ["Semi-private", "semi-private"],
  ["Private", "private"],
  ["Studio", "studio"],
  ["1 Bedroom", "1-bedroom"],
  ["2 Bedroom", "2-bedroom"]
];

export const getRoomType = (type, {by="human"}={}) => {
  const index = by === "human" ? 0 : 1;
  const option = _.find(ROOM_TYPE_OPTIONS, {[index]: type});
  if (option) {
    return option[index === 0 ? 1 : 0];
  }
}
