export function CareLevelIcon({careLevels, className}) {
  const residential = _.includes(careLevels, 'res');
  const name = residential ? 'home' : 'building';
  const ariaLabel = residential ? 'residential' : 'commercial';

  return <i className={`icon-${name} facility-type ${className}`} aria-label={ariaLabel} />;
};


const backgroundStyle = (url) => url ? {backgroundImage: `url(${url}`} : {};

export default function CommunityImage({community={}}) {
  return (
    <div className="community-image" style={backgroundStyle(community.photo_small)}>
      {!community.photo_small && <CareLevelIcon careLevels={community.care_levels}/>}
    </div>
  );
}
