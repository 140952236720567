import { Link } from "react-router-dom"

export default function CompleteCompanyProfile() {
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-details">
          <Link to="/learn-more"><h3 className="title">Complete Company Profile</h3></Link>
          <p>Add your logo, website + more</p>
        </div>
      </div>
      <div className="card-section card-extra-container">
        <Link className="button" to="/statuses/update">Post Update</Link>
        <Link to="/statuses" className="updates-link-text">View Updates</Link>
      </div>
    </div>
  )
}
