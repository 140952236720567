import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import Point from 'helpers/point';
import MapDisplay from 'components/MapDisplay';
import SearchResultsList from 'components/SearchResultsList';

export default function AgentDashboard() {
  const user = useSelector((state) => state.user);
  const communities = useSelector((state) => state.communities);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [displayMap, setDisplayMap] = useState(true);
  const [selectedSearchCommunity, setSelectedSearchCommunity] = useState({});
  const userLocation = new Point(user?.agent_profile);

  return <>
    <div className="tabs-centered hidden-desktop">
      <button onClick={() => setDisplayMap(true)} disabled={displayMap}>View Map</button>
      <button onClick={() => setDisplayMap(false)} disabled={!displayMap}>View List</button>
    </div>
    <div className={classnames('map-page-display', {mapview: displayMap})}>
      <MapDisplay
        communities={communities}
        userLocation={userLocation}
        hoveredItem={hoveredItem}
        setSelectedSearchCommunity={setSelectedSearchCommunity}
      />
      <SearchResultsList
        communities={communities}
        userLocation={userLocation}
        setHoveredItem={setHoveredItem}
        displayMap={() => setDisplayMap(true)}
        selectedSearchCommunity={selectedSearchCommunity}
      />
    </div>
  </>;
}
