import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import separateCareLevels from 'helpers/separateCareLevels';
import CommunityImage from './CommunityImage';

export default function CommunityCard({community, extra=null, onMouseEnter, onClick, children, show_missing_info=false, show_company_info=false, distanceRequired=true}) {
  const distance = distanceRequired ? (community.distanceFromLocation || community.distance) : null;
  const [missingInfo, setMissingInfo] = useState(community?.incomplete_profile_information)
  const {company} = community;
  useEffect(() => {
    if(_.includes(community?.incomplete_profile_information, "cover_photo")) {
      setMissingInfo(_.without([...missingInfo, "photo"], "cover_photo"));
    }
  }, []);

  return (
    <div className={classnames("card", "community-card", {clickable: onClick, hoverable: onMouseEnter})}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {company?.premium && show_company_info && (
        <div className="company-bar">
          {company.logo && <img src={company.logo} alt={company.name} />}
          <Link className="link-text" to={`/companies/${company.id}`}>{company.name}</Link>
        </div>
      )}
      {(_.size(missingInfo) != 0) && show_missing_info && (
        <div className="missing-info-text">
          <Link className="link-text" to={`/communities/${community.id}/edit`}>
            Complete Profile:
          </Link> Missing {_.map(missingInfo).join(', ')}
        </div>
      )}
      
      <div className="card-header">
        <CommunityImage community={community}/>
        <div className="card-details">
          <Link to={`/communities/${community.id}`} target="_blank">
            <h3 className='title'>{community.name}</h3>
          </Link>
          <p><span>{community.address},</span> <span>{community.city}, {community.state} {community.zip_code}</span></p>
          <p>
            {distanceRequired && _.isNumber(distance) && <span className="card-details-stat">{_.round(distance, 1)} mi</span>}
            {community.capacity && <span className="card-details-stat">{community.capacity} beds</span>}
            {community.care_levels && <span className="card-details-stat">{separateCareLevels(community.care_levels)}</span>}
          </p>
        </div>
        {extra}
      </div>
      {children}
    </div>
  );
}
