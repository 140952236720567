import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ReactPlayer from 'react-player'

import { stopPropagation, preventDefault } from "helpers/events";

export default function HintCard({firstCommunityId}) {
  const [selectedHint, setSelectedHint] = useState('');
  const [hoverHint, setHoverHint] = useState('');

  const hints = { 
    profile : (
      <div className="hint-selected">
        <p>
          Add a photo, description and more to your community.{" "}
          <Link to={`/communities/${firstCommunityId}/edit`}>Add Profile</Link>
        </p>
        
        { false && 
          <div className="hint-card-video-container">
            <ReactPlayer 
              className="react-player" 
              url={ video_urls[0] }
              height='100%'
              width='100%'
              controls={ true } 
            />
          </div>
        }

      </div>
    ),
    followers : (
      <div className="hint-selected">
        <p>
          Notify your Referral Sources about your community.{" "}
          <Link to={`/communities/${firstCommunityId}/followers`}>Add Followers</Link>
        </p>
      </div>
    ),
    updates : (
      <div className="hint-selected">
        <p>
          Share availability, specials and more with your Followers.{" "}
          <Link to="/statuses/update">Post Updates</Link>
        </p>
      </div>
    )
  }

  const toggleHint = (value) => setSelectedHint(value === selectedHint ? "" : value);
  const displayedHint = hoverHint || selectedHint;

  return (
    <div className="card hint-card" onMouseOut={() => setHoverHint()}>
      <span className="hint-caption">
        <i className="icon-lightbulb" />Finish Getting Started
      </span>
      <div className="getting-started-nav" onMouseOut={stopPropagation()}>
        <div className={classnames('getting-started-nav-item', {active: displayedHint === 'profile'})}>
          {firstCommunityId && (
            <a onClick={preventDefault(() => {toggleHint('profile')})} onMouseOver={() => setHoverHint('profile')}>
              <i className="icon-home" />
              <div>1. Add Profile</div>
            </a>
          )}
        </div>
        <div className={classnames('getting-started-nav-item', {active: displayedHint === 'followers'})}>
          <a onClick={preventDefault(() => {toggleHint('followers')})} onMouseOver={() => setHoverHint('followers')} 
          >
            <i className="icon-clock" />
            <div>2. Add Followers</div>
          </a>
        </div>
          <div className={classnames('getting-started-nav-item', {active: displayedHint === 'updates'})}>
            <a onClick={preventDefault(() => {toggleHint('updates')})} onMouseOver={() => setHoverHint('updates')}
            >
              <i className="icon-clock" />
              <div>3. Post Updates</div>
            </a>
        </div>
      </div>
      <div onMouseOut={stopPropagation()}>
        {hints[displayedHint]}
      </div>
    </div>
  )
}
