import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { invite } from 'actions/communities';
import { preventDefault } from 'helpers/events';

const defaultTo = (defaultValue) => (state, item) => ({...state, [item.id]: defaultValue});

export default function InvitePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const communities = useSelector((state) => state.communities);

  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [selectedCommunities, setSelectedCommunities] = useState(communities.reduce(defaultTo(true), {}));
  const setSelectedCommunity = (id, value) => setSelectedCommunities({...selectedCommunities, [id]: value});

  if (!_.get(user, 'community_rep?')) {
    return null;
  }

  const send = _.throttle(async () => {
    setError(null);
    if (email !== confirmEmail) {
      return setError("Email and Confirm Email must match.")
    }
    const community_ids = _.keys(_.pickBy(selectedCommunities, (value) => value === true));
    if (!community_ids.length) {
      return setError("Select at least one community.")
    }
    try {
      const result = await dispatch(invite({email, community_ids}));
      navigate('/', {state: {success: "Invitation Sent"}});
    } catch (e) {
      setError(_.get(e, 'response.data.errors') || e.message);
    }
  }, 800);

  return (
    <form className="main-container" onSubmit={preventDefault(send)}>
      {error && <div className="alert">{error}</div>}
      <label className="form-row">
        Email:
        <input value={email} onChange={(e) => setEmail(e.target.value)}/>
      </label>
      <label className="form-row">
        Confirm Email:
        <input value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)}/>
      </label>
      <label className="form-row">Select Communities:</label>
      {communities.map((community) => (
        <label key={community.id} className="checkbox-row">
          <input type="checkbox"
            checked={!!selectedCommunities[community.id]}
            onChange={(e) => setSelectedCommunity(community.id, e.target.checked)}/>
          <span className="checkable">{community.name}</span>
        </label>
      ))}
      <div className="button-container">
        <button>Send</button>
        <Link to="/" className="button pseudo">Cancel</Link>
      </div>
    </form>
  );
}
