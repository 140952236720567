import { combineReducers } from 'redux';
import communities from './communities';
import companies from './companies';
import conversations from './conversations';
import search from './search';
import statuses from './statuses';
import user from './user';

export default combineReducers({
  communities,
  companies,
  conversations,
  search,
  statuses,
  user
});
