import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from '@rollbar/react';
import { useSelector } from 'react-redux'
import { Routes, Route, Outlet, Navigate, Link, useMatch, useLocation } from 'react-router-dom';
import { useRollbarPerson } from '@rollbar/react';
import mixpanel from 'mixpanel-browser';
import classnames from 'classnames';

import FormLink from 'helpers/FormLink';
import { allCommunitiesPremium } from './helpers/communities';
import ProfileImage from 'helpers/ProfileImage';

import ErrorPage from 'components/ErrorPage';
import SearchBox from 'components/SearchBox';
import InvitePage from 'components/InvitePage';
import Conversation from 'components/Conversation';
import ConversationList from 'components/ConversationList';
import CommunityProfile from 'components/communities/CommunityProfile';
import CommunityStatusHistory from 'components/communities/CommunityStatusHistory';
import CommunityRepDashboard from 'components/CommunityRepDashboard';
import CommunityFollowerList from 'components/community_reps/CommunityFollowerList';
import CommunityStatusFeed from 'components/community_reps/CommunityStatusFeed';
import CommunityStatusUpdates from 'components/community_reps/CommunityStatusUpdates';
import StatusDetail from 'components/community_reps/StatusDetail';
import CompanyPage from 'components/CompanyPage';
import InfoPage from 'components/InfoPage';
import MenuDrawer from 'components/MenuDrawer';
import EditProfileForm from 'components/EditProfileForm';
import CommunitiesListForm from 'components/communities/CommunitiesListForm';
import CommunityDetailsForm from 'components/communities/CommunityDetailsForm';
import AgentDashboard from './components/AgentDashboard';
import AccessDenied from './components/AccessDenied';

const Catch = ({location}) => <ErrorBoundary key={location.key} fallbackUI={ErrorPage}><Outlet/></ErrorBoundary>;

const RegisteredOnly = ({user}) => {
  return _.get(user, 'registered?') ? <Outlet/> : <AccessDenied/>;
}

function App() {
  const location = useLocation();
  const currentConversation = useMatch('/conversations/:id');
  const id = currentConversation?.params.id;
  const user = useSelector((state) => state.user);
  const conversation = useSelector(({conversations}) => _.find(conversations, {id}));
  const community = useSelector(({communities}) => _.find(communities, {id: conversation?.community_id} ?? {}));
  const communities = useSelector((state) => state.communities);
  const isCommunityRep = _.get(user, 'community_rep?');
  const isAgent = _.get(user, 'agent?');
  const missingInfo = _.get(user, 'registered?') && _.get(user, 'missing_info?');
  const communitiesMissingDetails = _.some(communities, 'missing_info?');
  const [menuVisible, setMenuVisible] = useState(false);

  useRollbarPerson(user);

  useEffect(() => {
    mixpanel.track('Page visit');
  }, [location]);

  const viewingConversation = !!useMatch('/conversations/:id');
  const onHomepage = !!useMatch('/')
  const agentOnSearchPage = isAgent && onHomepage;
  const canAccessStatusesUpdate = isCommunityRep && allCommunitiesPremium(communities);
  const userProfile = isCommunityRep ? user : user?.agent_profile;
  const supportLink = process.env.SUPPORT_FORM_LINK && user?.email ?
    `${process.env.SUPPORT_FORM_LINK}${encodeURIComponent(user?.email)}` :
    null;

  return (
    <div className={classnames('App', {'conversation-page': viewingConversation})}>
      <header className="site-header">
        <div className="main-site-header">
          <Link to="/" className="home-link">
            <h1 className="site-title">
              <span className="hidden-mobile">Connect</span>
            </h1>
          </Link>
          {agentOnSearchPage && <SearchBox />}
          <div className="user-nav">
            {user && <>
              {!missingInfo && <>
                {isCommunityRep ? (
                  <Link to="/invite" className="nav-item">
                    <i className="icon-user-plus" title="Send Invite" aria-label="invite" />
                    <span className="nav-item-title">Invite</span>
                  </Link>
                ) : (
                  <FormLink className="nav-item" name={userProfile?.full_name} email={user.email} link="invite" phone={userProfile?.phone_number}>
                    <i className="icon-user-plus" title="Send Invite" aria-label="invite" />
                    <span className="nav-item-title">Invite</span>
                  </FormLink>
                )}
                <Link to="/conversations" className="nav-item">
                  <i className="icon-chat" title="Conversations" aria-label="conversations" />
                  <span className="nav-item-title">Messages</span>
                </Link>
              </>}
              <div className="nav-item">
                <ProfileImage user={user} onClick={() => setMenuVisible(true)} />
                <span className="nav-item-title" onClick={() => setMenuVisible(true)}>Menu</span>
              </div>
            </>}
            {viewingConversation && supportLink && (
              <span>
                <a href={supportLink} target="_blank">
                  <i className="icon-help-circled" title="Help" aria-label="help" />
                </a>
              </span>
            )}
            {!user && <a href="/users/sign_in" className="button">Log In</a>}
          </div>
        </div>
        {currentConversation && community && (
          <div className="sub-site-header">
            <h2>{community.name}</h2>
            <p>{community.address}, {community.city}, {community.state} {community.zip_code}</p>
            <div className="avatar-container">
              <p>In this chat:</p>
              {isAgent && community.communities_users.map((user) => <ProfileImage key={user.id} user={user} />)}
            </div>
            {isCommunityRep && (
              <div>
                <Link to="/">Manage Community Contacts</Link>
                <span className="link-separator"> | </span>
                <Link to="/statuses/update">Share Availability + More</Link>
              </div>
            )}
          </div>
        )}
        {false && !currentConversation && isCommunityRep && (
          <div className="connect-plus-sub-header">
            <div>Connect+</div>
          </div>
        )}
      </header>
      <div className="App-body">
        <Routes>
          <Route element={<Catch location={location}/>}>
            <Route index element={(() => {
              if (!user) return null;
              if (!user['registered?']) return <AccessDenied/>;
              if (missingInfo) return <Navigate to="/user/edit"/>;
              if (communitiesMissingDetails) return <Navigate to="/communities/edit"/>;

              return isCommunityRep ? <CommunityRepDashboard /> : <AgentDashboard/>
            })()}/>
            <Route path="/learn-more" element={<InfoPage />}/>
            {user && <>
              <Route element={<RegisteredOnly user={user}/>}>
                <Route path="/user/edit" element={<EditProfileForm profile={user} />}/>
              </Route>
              {isCommunityRep && <Route path="/communities/edit" element={<CommunitiesListForm/>}/>}
              <Route path="/conversations/:id" element={currentConversation ? <Conversation/> : <Navigate to="/"/>}/>
              <Route path="/conversations" element={<ConversationList />}/>
              {(missingInfo || communitiesMissingDetails) ? <Route path="*" element={<Navigate to="/"/>}/> : <>
                <Route path="/communities/:id" element={<CommunityProfile />}/>
                <Route path="/communities/:id/statuses" element={<CommunityStatusHistory />}/>
                <Route path="/companies/:id" element={<CompanyPage />}/>
                <Route element={<RegisteredOnly user={user}/>}>
                  <Route path="/communities/:id/edit" element={<CommunityDetailsForm />}/>
                  <Route path="/communities" element={<CommunityRepDashboard />}/>
                  <Route path="/communities/:id/followers" element={isCommunityRep ? <CommunityFollowerList /> : <Navigate to="/"/>}/>
                  <Route path="/statuses/:id" element={<StatusDetail />}/>
                  <Route path="/statuses/update" element={canAccessStatusesUpdate ? <CommunityStatusUpdates /> : <Navigate to="/"/>}/>
                  <Route path="/statuses" element={isCommunityRep ? <CommunityStatusFeed /> : <Navigate to="/"/>}/>
                  <Route path="/invite" element={<InvitePage />}/>
                </Route>
              </>}
            </>}
          </Route>
        </Routes>
      </div>
      {user && <MenuDrawer open={menuVisible} setOpen={setMenuVisible} user={user} />}
    </div>
  );
}

export default App;
