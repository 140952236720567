import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ProfileImage from 'helpers/ProfileImage';
import CommunityImage from 'components/communities/CommunityImage';

const displayDate = (date) => dayjs(date).format("MM/DD/YY h:mm A");
const participants = (conversation) => [conversation?.agent, ...(conversation?.community_reps || [])];
const lastAuthorName = (conversation) => _.find(participants(conversation), {id: conversation.last_author_id})?.full_name;
const conversationWithCommunities = ({conversations, communities}) => conversations.map((conversation) => ({
  ...conversation,
  community: _.find(communities, {id: conversation.community_id})
}));

export default function ConversationList() {
  const conversations = useSelector(conversationWithCommunities);
  const sortedConversations = _.reverse(_.sortBy(conversations, 'updated_at'));
  const isCommunityRep = useSelector(({user}) => _.get(user, 'community_rep?'));

  return <div className="page-container">
    <h3>Conversations</h3>
    {isCommunityRep && (
      <div className="contacts">
        <p>Verify your community's contacts are up-to-date.</p>
        <Link to="/" className="button secondary">Manage Contacts</Link>
      </div>
    )}
    {!conversations.length ? (
      <div>There are no conversations yet</div>
    ) : (
      sortedConversations.map(({community, ...conversation}) => (
        <Link key={conversation.id} to={`/conversations/${conversation.id}`}>
          <div className="card conversation-card">
            {isCommunityRep ? (
              <div className="card-header">
                <ProfileImage user={conversation.agent}/>
                <div className="card-details">
                  <h3 className="name">{conversation.agent ? conversation.agent.full_name : "User is no longer available"}</h3>
                  <p>{conversation.agent ? conversation.agent.company_name : ""}</p>
                  <p>{community?.name}</p>
                  <p className="timestamp">{lastAuthorName(conversation)} | <time dateTime={conversation.updated_at}>{displayDate(conversation.updated_at)}</time></p>
                </div>
              </div>
            ) : (
              <div className="card-header">
                <CommunityImage community={community}/>
                <div className="card-details">
                  <h3 className="name">{community?.name}</h3>
                  <p>
                    {community?.address}, {community?.city}, {community?.state} {community?.zip_code}
                  </p>
                  <p className="timestamp">{lastAuthorName(conversation)} | <time dateTime={conversation.updated_at}>{displayDate(conversation.updated_at)}</time>
                  </p>
                </div>
              </div>
            )}
          </div>
        </Link>
      ))
    )}
  </div>
}
