const EmailSubscriptions = ({details, setDetails}) => {
  const email_subscriptions = details.email_subscriptions || {};
  const updateSubscription = (subscriptionName) => () => {
    setDetails((details) => ({
      ...details,
      email_subscriptions: {
        ...email_subscriptions,
        [subscriptionName]: email_subscriptions[subscriptionName] === false
      }
    }));
  };

  const EmailPreferenceOption = ({name}) => {
    return (
      <label className="radio-label">
        <input
          type="checkbox"
          checked={_.get(email_subscriptions, name) !== false}
          onChange={updateSubscription(name)}
        />
        <span className="checkable">
          {_.startCase(name)}
        </span>
      </label>
    )
  }

  return (
    <div className="form-container">
      <h3 className="notifications-header">Email Notifications</h3>
      <div className="email-preference-options">
        <EmailPreferenceOption name="new_message_received" />
        <p className="warning">
          If you're the only user associated with a community you may still receive these messages. Contact Mimi for questions.
        </p>
        <EmailPreferenceOption name="monday_morning" />
        <br />
        <EmailPreferenceOption name="update_has_been_viewed" />
        <br />
        <EmailPreferenceOption name="new_follower" />
      </div>
    </div>
  );
}

export default EmailSubscriptions;
