import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CommunityCard from 'components/communities/CommunityCard';
import CommunityActions from 'components/communities/CommunityActions';
import Status from 'components/communities/Status';

import pluralize from 'pluralize';

export default function CompanyCard({company}) {
  const [displayCommunities, setDisplayCommunities] = useState(true);
  const isCommunityRep = useSelector(({user}) => _.get(user, 'community_rep?'));

  const compactCommunities = _.compact(company.communities);
  const mergeCommunities = (selector) => (state) => selector(state).map((item) => ({
    ...item,
    community: _.find(company.communities, {id: item.community_id})
  }));

  const companyStatuses = () => _.filter(company.statuses, status => {
    const communityIds = _.map(compactCommunities, community => community.id);
    return communityIds.includes(status.community_id);
  });
  const statuses = useSelector(mergeCommunities(companyStatuses));
  const sortedStatuses = _.reverse(_.sortBy(statuses, 'created_at'));

  return <>
    <div className="card company-card">
      <div className="card-header">
        {company.logo && <img src={company.logo} alt={company.name} />}
        <div className="card-details">
          <h3>{company.name}</h3>
          <p>{pluralize("Communities", compactCommunities?.length, true)}</p>
        </div>
      </div>
      <div className="card-body">
        <div className="card-actions company-tab-actions">
          <button onClick={() => setDisplayCommunities(true)} disabled={displayCommunities}>Company</button>
          <button onClick={() => setDisplayCommunities(false)} disabled={!displayCommunities}>Updates</button>
        </div>
      </div>
    </div>
    {displayCommunities ? (
      _.map(compactCommunities, (community) => (
        <CommunityCard key={community.id} community={community} show_missing_info={isCommunityRep}>
          {!isCommunityRep && <CommunityActions community={community} withinProfile={true}/>}
          {community.status && <Status status={community.status}/>}
        </CommunityCard>
      ))
    ) : (
       _.map(sortedStatuses, (update) => (
        <CommunityCard key={update.id} community={update.community} distanceRequired={false}>
          <Status status={update}/>
        </CommunityCard>
      ))
    )}
  </>
}
