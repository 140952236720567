import React from 'react';
import { Link } from 'react-router-dom';

export default function InfoCard({children}) {
  return (
    <div className="card updates-link">
      <span className="updates-caption">Share Availability, Specials & More with Agents Near You</span>
      <span className="updates-text">Free Limited Time Trial</span>
      <div className="card-extra-container">
        <Link to="/statuses/update" className="button">
          Share Availability With Agents
        </Link>
        <Link to="/learn-more" className="button">
          Learn More About Mimi Connect
        </Link>
      </div>
      {children}
    </div>
  )
}
