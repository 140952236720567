import dayjs from 'dayjs';

const DEFAULT_FORMAT = "MM/DD/YY h:mm A";

export default function Timestamp({dateTime, format=DEFAULT_FORMAT}) {
  return (
    <p className="timestamp">
      <time dateTime={dateTime}>
        {dayjs(dateTime).format(format)}
      </time>
    </p>
  )
}