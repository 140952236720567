import { DirectUpload } from "@rails/activestorage"
import React, { useState, useEffect, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { rails_direct_uploads_url } from 'helpers/rails';
import defaultAvatar from 'images/no-headshot.png';
import Loading from 'components/shared/Loading';

const DEFAULT_SCALE = 1.2;
const DEFAULT_POSITION = {x: 0.5, y: 0.5};

const fileFromUrl = async (url) => {
  const filename = _.last(url.split("/"));
  const ext = _.last(url.split("."));
  return await fetch(url)
    .then(r => r.blob())
    .then(blobFile => new File([blobFile], filename, {type: `image/${ext}`}));
}

const positionFromCrop = (crop) => (
  crop?.width ? {x: crop.x + crop.width / 2, y: crop.y + crop.height / 2} : DEFAULT_POSITION
);

export function ProfileAvatarInput(props) {
  return (
    <ImageInput
      width={200}
      height={200}
      radius={100}
      placeholder={defaultAvatar}
      placeholderText="Click to select a profile photo"
      {...props}/>
  );
}

export function CoverPhotoInput(props) {
  return (
    <ImageInput
      width={400}
      height={200}
      radius={0}
      placeholderText="Click to select a cover photo"
      {...props}/>
  );
}

const cancelable = (callback) => {
  let canceled;
  return [
    () => canceled = true,
    (...args) => !canceled && callback(...args)
  ];
};

let cancelUpload = _.noop;

export default function ImageInput({
  width, height, radius=0, border=25, placeholder, placeholderText,
  initialImage, crop, onImageChange, onCropChange, loading, setLoading=_.noop, needsUpgrade
}) {
  const [image, setImage] = useState(initialImage);
  const [position, setPosition] = useState(positionFromCrop(crop));
  const [scale, setScale] = useState(_.isEmpty(crop) ? DEFAULT_SCALE : 1 / Math.max(crop.width, crop.height));
  const [error, setError] = useState('');
  const editor = useRef(null);

  const updateSize = (position) => {
    if (position) setPosition(position);
    onCropChange(editor.current.getCroppingRect());
  };
  const updateScale = (e) => {
    setScale(_.toNumber(e.target.value));
    updateSize();
  };

  const upload = (newImage) => {
    if (!newImage.type.startsWith("image") || newImage.size < 200) return;

    setLoading(true);
    setError('');
    setImage(newImage);
    setPosition(DEFAULT_POSITION);
    cancelUpload();

    const upload = new DirectUpload(newImage, rails_direct_uploads_url());
    let onUploadComplete;
    [cancelUpload, onUploadComplete] = cancelable((error, blob) => {
      setLoading(false);
      if (error) {
        setError(error.message || error);
        setImage(null);
        console.warn(error);
      } else {
        onImageChange(blob.signed_id);
        updateSize();
      }
    })
    upload.create(onUploadComplete);
  };

  useEffect(() => {
    if (needsUpgrade) {
      try {
        fileFromUrl(initialImage).then(upload);
      } catch (e) {
        console.warn('image upgrade failed', e);
      }
    }
  }, []);

  const clear = () => {
    cancelUpload();
    setLoading(false);
    setError('');
    setImage(null);
    setPosition(DEFAULT_POSITION);
    setScale(DEFAULT_SCALE);
    onImageChange(null);
    onCropChange({});
  };

  return (
    <Dropzone
      onDrop={(dropped) => upload(dropped[0])}
      onError={(error) => setError(error.message)}
      noClick={!!image}
      multiple={false}
      accept={{"image/*": []}}
    >
      {({getRootProps, getInputProps}) => (
        <div {...getRootProps({className: 'image-input', style: {width: `${width + border*2}px`, height: `${height + border*2}px`}})}>
          {error && <div className="alert">{error}</div>}
          {loading && <Loading />}
          <AvatarEditor ref={editor}
            image={image}
            position={position}
            onPositionChange={updateSize}
            scale={scale}
            width={width}
            height={height}
            border={border}
            borderRadius={radius}/>
          <input {...getInputProps()} />
          {!!image ? <>
            <input type="range" value={scale} onChange={updateScale} min="1" max="3" step="any"/>
            <button type="button" className="clear" onClick={clear}>&times;</button>
          </> : <>
            {placeholder && <img src={placeholder} className="placeholder-image"/>}
            <div className="placeholder-text">{placeholderText}</div>
          </>}
        </div>
      )}
    </Dropzone>
  );
}
