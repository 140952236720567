import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { loadCommunity } from 'actions/communities'
import ProfileImage from 'helpers/ProfileImage';
import separateCareLevels from 'helpers/separateCareLevels';
import { CARE_LEVELS, CARE_LEVEL_QUESTIONS, getRoomType } from 'helpers/communities';
import { enterConversation } from 'helpers/enterConversation';
import Loading from 'components/shared/Loading';
import { CareLevelIcon } from './CommunityImage';
import CommunityActions from './CommunityActions';
import FlashMessage from '../shared/FlashMessage';

const displayDate = (date) => dayjs(date).format("MM/DD/YY h:mm A");

const humanCareCapacites = {
  res: "Capacity",
  il: "Independent Living",
  al: "Assisted Living",
  mc: "Memory Care",
  sn: "Skilled Nursing"
}

const localizer = new Intl.NumberFormat('en-US', {
  style: 'currency', currency: 'USD', trailingZeroDisplay: "stripIfInteger"
});

const formatPrice = (p) =>  {
  const n = _.toNumber(p);
  if (!p || _.isNaN(n)) return;

  return localizer.format(n).replace(/\.00$/, '');
}

export default function CommunityProfile() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [params, ] = useSearchParams();
  const showFollowMessage = params.has("follow");
  const isCommunityRep = useSelector((state) => _.get(state.user, 'community_rep?'));
  const communities = useSelector((state) => state.communities);
  const community = _.find(communities, {id: Number(id)});
  const reps = _.filter(community?.communities_users, {active: true, pending: false});
  const company = community?.company;

  useEffect(() => {
    if (!community) {
      dispatch(loadCommunity(id))
    }
  }, [])

  if (!community) return <Loading />;

  const care_levels = _.sortBy(community.care_levels, [(l) => l === 'res' ? 0 : 1]);

  return (
    <div className="page-container">
      {showFollowMessage && <FlashMessage message={`You have ${params.get("follow") === "true" ? "followed" : "unfollowed"} this community.`}/>}
      {isCommunityRep && <div className="card hint-card">
        <span className="hint-caption">Review Profile & Contacts</span>
        <span className="hint-text">
          <Link className="views-text" to={`/communities/${community.id}/edit`}>Edit Profile</Link> to update your community details.
          <Link className="views-text" to="/invite"> Invite Coworkers</Link> to list them on this page.
        </span>
      </div>}
      {company && (isCommunityRep || company.premium) && (
        <div className="card company-card">
          <div className="card-header">
            {company.logo && <img src={company.logo} alt={company.name} />}
            <div className="card-details">
              <h3><Link className="link-text" to={`/companies/${company.id}`}>{company.name}</Link></h3>
              <p>{pluralize("Communities", company.community_count, true)}</p>
            </div>
          </div>
        </div>
      )}
      <div className="card community-profile">
        <div className="card-header community-header">
          <div className="card-details">
            <h3>{community.name}</h3>
            <p><span>{community.address},</span> <span>{community.city}, {community.state} {community.zip_code}</span></p>
            <p>
              {community.capacity && <span className="card-details-stat">{community.capacity} Beds</span>}
              {community.care_levels && <span className="card-details-stat">{separateCareLevels(community.care_levels)}</span>}
            </p>
          </div>
          {isCommunityRep ? (
            <div className="community-nav-display">
              <Link to="/conversations">
                <i className="icon-mail-alt" /> Messages
              </Link>
              <Link className="community-nav updates-link-text" to={`/communities/${community.id}/followers`}>
                <i className="icon-wifi" />
                {pluralize("Followers", community.followers.length, true)}
              </Link>
            </div>
          ) : (
            <div className="community-actions">
              <CommunityActions community={community} enterConversation={enterConversation} withinProfile={true}/>
            </div>
          )}
        </div>
        <div className="community-photo"
          style={community.photo ? {backgroundImage: `url(${community.photo})`, padding: '0 0 50% 0'} : {}}
        >
          {_.isEmpty(community.photo) && <CareLevelIcon careLevels={community.care_levels}/>}
        </div>
        <div className="card-details">
          <p className="community-description">
            {community.description || "No description available."}
          </p>

          <h3 className="community-section-header">
            Updates
            <Link to={`/communities/${community.id}/statuses`} className="header-link">
              View All Updates
            </Link>
          </h3>
          <hr />
          <p className="community-update">{_.get(community, 'status.content', 'No current update available.')}</p>
          <h3 className="community-section-header">{pluralize('Contact', reps.length, true)}</h3>
          <hr />
          {reps.map((user) => (
            <div key={user.id} className="community-rep">
              <ProfileImage user={user}/>
              <div className="rep-details">
                <h5 className="community-rep-name">{user.full_name}</h5>
                <p>{user.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="card-details">

          <h3 className="community-section-header">
            General
          </h3>
          <hr />

          <div className="community-room-type-detail">
              <div className="community-room-type-name">
                Profile Last Updated
              </div>
              <div className="community-room-type-price">
                <time dateTime={community.updated_at}>{displayDate(community.updated_at)}</time>
              </div>
          </div>

          {Object.keys(community.care_capacities).map((key) => (
            <div className="community-room-type-detail" key={ key }>
              <div className="community-room-type-name">
                { humanCareCapacites[key] }
              </div>
              <div className={ _.isEmpty(community.care_capacities[key]) ? "community-no-response-value" : "community-room-type-price" }>
                { community.care_capacities[key] + " Beds" || 'Capacity Not Available' }
              </div>
            </div>
          ))}

          <div className="community-room-type-detail">
              <div className="community-room-type-name">
                Accepts Medicaid
              </div>
              <div className={_.isEmpty(community.accepts_medicaid) ? "community-no-response-value" : "community-room-type-price"}>
                { _.startCase(community.accepts_medicaid) || 'No Response' }
              </div>
          </div>

          { community.state === 'CO' &&
            <div className="community-room-type-detail">
              <div className="community-room-type-name">
                Accepts InnovAge
              </div>
              <div className={_.isEmpty(community.accepts_innovage) ? "community-no-response-value" : "community-room-type-price"}>
                { _.startCase(community.accepts_innovage) || 'No Response' }
              </div>
            </div>
          }

          { community.state === 'AZ' &&
            <div>
              <div className="community-room-type-detail">
                <div className="community-room-type-name">
                  Accepts ALTC's Banner
                </div>
                <div className={_.isEmpty(community.accepts_altcs_banner) ? "community-no-response-value" : "community-room-type-price"}>
                  { _.startCase(community.accepts_altcs_banner) || 'No Response'}
                </div>
              </div>
              <div className="community-room-type-detail">
                <div className="community-room-type-name">
                  Accepts ALTC's Mercy
                </div>
                <div className={_.isEmpty(community.accepts_altcs_mercy) ? "community-no-response-value" : "community-room-type-price"}>
                  { _.startCase(community.accepts_altcs_mercy) || 'No Response'}
                </div>
              </div>
              <div className="community-room-type-detail">
                <div className="community-room-type-name">
                  Accepts ALTC's United
                </div>
                <div className={_.isEmpty(community.accepts_altcs_united) ? "community-no-response-value" : "community-room-type-price"}>
                  { _.startCase(community.accepts_altcs_united) || 'No Response'}
                </div>
              </div>
            </div>
          }

        </div>
        <div className="card-details">
          { _.map(care_levels, (care_level) => (
            <React.Fragment key={care_level}>
              <h3 className="community-section-header">
                { care_level === 'res' ? "Details" : CARE_LEVELS[care_level] }
              </h3>
              <hr />
              <div className="community-room-type-details" >
               
                <div className="community-room-type-boilerplate">
                  { "Room Types & Starting Price" }
                </div>
                <div className="community-room-type-detail">
                    <div className="community-room-type-name">
                      All-Inclusive Pricing
                    </div>
                    <div className={_.isEmpty(community[care_level + "_all_inclusive_pricing"]) ? "community-no-response-value" : "community-room-type-price"}>
                      { _.startCase(community[care_level + "_all_inclusive_pricing"]) || 'No Response'}
                    </div>
                </div>
                <div className="community-room-type-detail">
                    <div className="community-room-type-name">
                      Community Fee
                    </div>
                    <div className={_.isNull(community[care_level + "_community_fee"]) ? "community-no-response-value" : "community-room-type-price"}>
                      { 
                        _.isNull(community[care_level + "_community_fee"]) ? 'No Response'
                        : community[care_level + "_community_fee"] === 0 ? 'No Community Fee'
                        : formatPrice(community[care_level + "_community_fee"])
                      }
                    </div>
                </div>
                <div className="community-room-type-detail">
                    <div className="community-room-type-name">
                      Deposit
                    </div>
                    <div className={_.isNull(community[care_level + "_deposit"]) ? "community-no-response-value" : "community-room-type-price"}>
                      { 
                        _.isNull(community[care_level + "_deposit"]) ? 'No Response'
                        : community[care_level + "_deposit"] === 0 ? 'No Deposit'
                        : formatPrice(community[care_level + "_deposit"])
                      }
                    </div>
                </div>
                { _.map(community.room_types[care_level], ({price=[]}={}, key) => (
                  <div className="community-room-type-detail" key={key}>
                      <div className="community-room-type-name">
                        {getRoomType(key, {by: "key"})}
                      </div>
                      <div className="community-room-type-price">
                        { "Starts at " + _.compact(_.map(price, formatPrice)).join(" - ")}
                      </div>
                  </div>
                ))}

                <div className="community-room-type-detail">
                    <div className="community-room-type-name">
                      Additional Pricing Information
                    </div>
                    <div className="community-description">
                      {community[care_level + "_comments_pricing"] || "No description available."}
                    </div>
                </div>
                
                <div className="community-room-type-boilerplate">
                  { "Available Care" }
                </div>
                { _.map(CARE_LEVEL_QUESTIONS[care_level], (question) => (
                  <div className="community-room-type-detail" key={ question }>
                      <div className="community-room-type-name">
                        { _.startCase(question) }
                      </div>
                      <div className={_.isEmpty(community[care_level + "_" + question]) ? "community-no-response-value" : "community-room-type-price"}>
                        { _.startCase(community[care_level + "_" + question]) || 'No Response' }
                      </div>
                  </div>
                ))}
                <div className="community-room-type-detail">
                    <div className="community-room-type-name">
                      Additional Care Information
                    </div>
                    <div className={_.isNull(community[care_level + "_comments_other"]) ? "community-no-response-value" : "community-description"}>
                      {community[care_level + "_comments_other"] || "No Response"}
                    </div>
                </div>
              </div>
            </React.Fragment>
          )) }
          { _.isEmpty(_.keys(community.care_levels)) && (
            <p>This community has not yet updated room types, pricing and care details.</p>
          ) }
        </div>
      </div>
    </div>
  );
}
