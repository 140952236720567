import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

export default function FormLink({name, lastName, email, title, community, children, link, phone, className}) {
  const communities = useSelector((state) => state.communities);
  const formattedName = encodeURIComponent(name);
  const formattedLastName = encodeURIComponent(lastName);
  const formattedEmail = encodeURIComponent(email);
  const formattedTitle = encodeURIComponent(title)
  const formattedCommunityName = encodeURIComponent(community?.name);
  const formattedCommunityAddress = encodeURIComponent(community?.full_address);
  const formattedCommunities = communities.map(community => encodeURIComponent(community.name) + '%0A' + encodeURIComponent(community.full_address)).join("%0A%0A")
  const formattedPhone = encodeURIComponent(phone);

  const linkMap = {
    contacts: `https://docs.google.com/forms/d/e/1FAIpQLScREpVGQkZXNsD9qzQ6jsvxO-SUv1kkQfzKDhTh7SdVXD2SJw/viewform?usp=pp_url&entry.1329077934=${formattedName}&entry.1451296285=${formattedEmail}`,
    updates:  `https://docs.google.com/forms/d/e/1FAIpQLSewOyffS09FoxAg58obwRQEB0--Al1Ym7fNAXoLfPYEbotVGQ/viewform?usp=pp_url&entry.1123098322=${formattedEmail}&entry.997808379=${formattedName}&entry.502123669=${formattedCommunities}`,
    register: `https://docs.google.com/forms/d/e/1FAIpQLSc-733fyYiOdosdcHvHGRI8Q58xPxRo60Cbp6yq-dJVoeXIiA/viewform?usp=pp_url&entry.636955096=${formattedName}&entry.1761238618=${formattedEmail}`,
    invite: `https://docs.google.com/forms/d/e/1FAIpQLSee8dKrkoJO2uQQBbBP1J8Q-f0C1R1V5pehWw_1lA4KLuXS7g/viewform?usp=pp_url&entry.2020029679=${formattedName}&entry.1530369580=${formattedEmail}${phone ? `&entry.187916094=${formattedPhone}` : ""}`,
    nonResProfile: `https://docs.google.com/forms/d/e/1FAIpQLSftEMxajkG0Vuu95UTDCjdzMcxgrmkmx0wVdkh_N0BxQ3ZlAg/viewform?usp=pp_url&entry.1855837568=${formattedEmail}&entry.1402547960=${formattedName}${phone ? `&entry.810940799=${formattedPhone}` : ""}&entry.1497541333=${formattedCommunityName}&entry.1436322483=&entry.225804368=&entry.1519309488=${formattedCommunityAddress}&entry.2007622184=&entry.611420202=&entry.524591276=&entry.132016851=&entry.1594296282=`,
    resProfile: `https://docs.google.com/forms/d/e/1FAIpQLSd0dhdWj1lAcHSrDOFDmdws39CrxSNEBBilz3lhCEgFUHnlOA/viewform?usp=pp_url&entry.1855837568=${formattedEmail}&entry.1402547960=${formattedName}${phone ? `&entry.810940799=${formattedPhone}` : ""}&entry.1497541333=${formattedCommunityName}&entry.1436322483=&entry.225804368=&entry.1519309488=${formattedCommunityAddress}&entry.2007622184=&entry.1594296282=&entry.1948109906=`,
    support: `https://docs.google.com/forms/d/e/1FAIpQLScEzCMK3p6wnRE43hXI-cHmv5VBbuHCQaB22R6NkR3HgV9_Qw/viewform?usp=pp_url&entry.1466271819=${formattedEmail}`,
    userProfile: `https://docs.google.com/forms/d/e/1FAIpQLScj81LQ4pDk7RVoLus5V_W7szPwO3dpXE8L7mzX_-bQIbn-nA/viewform?usp=pp_url&entry.1200042884=${formattedEmail}&entry.764006632=${formattedName}&entry.1649013318=${formattedLastName}&entry.1933808339=${formattedTitle}${phone ? `&entry.1623491138=${formattedPhone}` : ""}`
  }

  return (
    <a className={classnames({button: link !== "invite"}, className)} target="_blank" href={linkMap[link]}>{children}</a>
  );
}
