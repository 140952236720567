import { maps } from 'google_services';
import Point from 'helpers/point';
import { getAutocompleteResults } from 'helpers/google';
import { loadCommunitiesNearLocation } from 'actions/communities';

export function setSelectedCommunityID(id, searchMode) {
  return {type: 'search/setSelectedCommunityID', id, searchMode};
}
export function setSelectedCommunity({id=null}={}, searchMode) {
  return {type: 'search/setSelectedCommunityID', id, searchMode};
}

export function setSelectedLocation(place) {
  return async (dispatch, getState, api) => {
    const geocoder = new (await maps()).Geocoder();
    geocoder.geocode({placeId: place.place_id}, ([result]) => {
      const location = new Point(result.geometry.location);
      dispatch(loadCommunitiesNearLocation(location));
      dispatch({type: 'search/setSelectedLocation', place, location});
    });
  };
}

export function setSelectedAddress(searchString) {
  return async (dispatch, getState, api) => {
    getAutocompleteResults(searchString, ([place]) => {
      if (place) {
        dispatch({type: 'search/receivedPredictedPlace', place});
      }
    });
  }
}

export const clearSelectedLocation = () => ({type: 'search/setSelectedLocation'});
