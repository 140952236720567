import { useState, useEffect } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import ConversationChannel from 'channels/conversation_channel';
import { addMessage, loadConversation, loadMessages } from 'actions/conversations';
import { updateUser } from 'actions/user';
import { loadCommunity } from 'actions/communities'
import Messages from 'components/Messages';
import ProfileForm from 'components/ProfileForm';
import Loading from 'components/shared/Loading';

const MORE_MESSAGE_COUNT = 20;

const MAX_CONVERSATION_CHARACTERS = 1000;

const REQUEST_AVAILABILITY_MESSAGE = "Hello, please share your current availability, specials and more on Mimi Connect so I can stay up-to-date with your community.";

function validInput(value) {
  const len = _.trim(value).length;
  return len > 0 && len < MAX_CONVERSATION_CHARACTERS;
}

const validProfile = ({first_name, last_name, title}={}) => (
  _.every([first_name, last_name, title])
);
const isLoading = (items) => _.some(items, _.isEmpty);

const moreMessagesAvailable = ({message_count, messages}={}) => message_count > messages?.length;
const oldestDate = (messages) => _.first(_.sortBy(messages, 'created_at'))?.created_at;

export default function Conversation() {
  const {id} = useParams();
  const [params, __] = useSearchParams();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(params.get('requestAvailability') ? REQUEST_AVAILABILITY_MESSAGE : "");
  const [channel, setChannel] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  const user = useSelector(({user}) => user);
  const conversation = useSelector(({conversations}) => _.find(conversations, {id}));
  const community = useSelector(({communities}) => _.find(communities, {id: conversation?.community_id}) ?? {});
  const participants = [conversation?.agent, ...(conversation?.community_reps || [])];
  const loading = isLoading([conversation, community, user, ...(conversation?.message_count !== 0 ? [conversation?.messages] : [])]);
  const IS_MOBILE = width < 800;

  useEffect(() => {
    if (_.isEmpty(community) && conversation) {
      dispatch(loadCommunity(conversation.community_id))
    }
  }, [conversation])

  useEffect(() => {
    dispatch(loadConversation(id));
  }, []);

  useEffect(() => {
    if (!channel) {
      const channel = ConversationChannel.create(id);
      channel.received = ({message={}, action}) => dispatch(addMessage(id, message, action));

      setChannel(channel);
    }
  });

  function handleWindowSize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSize);
    return () => {
      window.removeEventListener('resize', handleWindowSize);
    }
  }, []);

  function sendMessage(message) {
    if (_.trim(message) !== '') {
      channel.speak(message);
      setMessage('');
    }
  }

  function enterMessage(e) {
    if (e.key === 'Enter' && e.shiftKey == false && !IS_MOBILE) {
      e.preventDefault();
      return sendMessage(e.target.value);
    }
  }

  function submitMessage(e) {
    e.preventDefault();
    sendMessage(message);
  }

  const updateInput = ({target: {value}}) => setMessage(value);
  const updateProfile = (e, fields) => {
    e.preventDefault();
    dispatch(updateUser(fields));
  };
  const getMessages = () => dispatch(loadMessages(id, MORE_MESSAGE_COUNT, oldestDate(conversation?.messages)));

  if (loading) return <Loading/>

  return (
    <div className="conversation">
      <ScrollToBottom className="scroll-bottom-container">
        {moreMessagesAvailable(conversation) && (
          <button type="button" onClick={getMessages}>Show Previous Messages</button>
        )}
        <Messages messages={conversation.messages} participants={participants} user={user}/>
      </ScrollToBottom>
      {(validProfile(user) || user.agent_profile) && user.messaging_allowed ? (
        <div className="new-message-container">
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            name="message"
            style={{ resize: 'none' }}
            value={message}
            placeholder="Send a message"
            onChange={updateInput}
            onKeyDown={enterMessage}
          />
          <div className="form-messages">
            {message.length > MAX_CONVERSATION_CHARACTERS && (
              <span className="error-message">Messages must be less than {MAX_CONVERSATION_CHARACTERS} characters.</span>
            )}
            <span className="helper-text">SHIFT + ENTER adds new line</span>
          </div>
          <button type="button" onClick={submitMessage} disabled={!validInput(message)}>Send</button>
        </div>
      ) : (
        <ProfileForm onSave={updateProfile} profile={user}/>
      )}
    </div>
  );
}
