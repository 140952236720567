import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { preventDefault } from 'helpers/events';
import { updateUser } from 'actions/user';
import { ProfileAvatarInput } from 'components/shared/ImageInput';
import EmailSubscriptions from 'components/EmailSubscriptions';

const REQUIRED_KEYS = ['first_name', 'last_name', 'title', 'phone_number'];
const trimRequired = (profile) => _.mapValues(_.pick(profile, REQUIRED_KEYS), _.trim);
const normalizeDetails = (profile) => ({
  ...trimRequired(profile),
  avatar: profile.avatar?.signed_id,
  avatar_url: profile.avatar?.url || profile.profile_photo,
  avatar_crop: profile.avatar_crop,
  email_subscriptions: profile.email_subscriptions
});

export default function EditProfileForm({profile}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCommunityRep = useSelector((state) => _.get(state.user, 'community_rep?'));
  const missingInfo = _.get(profile, 'missing_info?');
  const [details, setDetails] = useState(normalizeDetails(profile));
  const [loading, setLoading] = useState(false);

  const isValid = _.every(trimRequired(details));

  const updateProfile = async () => {
    if (!isValid) return;
    const result = await dispatch(updateUser(details));
    navigate('/', {state: {success: "Profile Updated"}});
  };
  const updateDetail = (name) => (e) => {
    setDetails((details) => ({...details, [name]: _.get(e, 'target.value', e)}));
  };

  return (
    <div className="page-container">
      {missingInfo && <div className="card hint-card">
        <span className="hint-caption"><i className='icon-lightbulb'></i>Let's Get Started</span>
        <span className="hint-text">Complete your user profile.</span>
      </div>}
      <div className="card form-centered">
        <h2>{missingInfo ? 'Complete' : 'Update'} Your Profile</h2>
        {missingInfo && <h5>To continue, enter your name and contact information.</h5>}
        <form className="profile-form" onSubmit={preventDefault(updateProfile)}>
          <div className="form-field">
            <ProfileAvatarInput
              needsUpgrade={details.avatar_url && !details.avatar}
              initialImage={details.avatar_url}
              crop={details.avatar_crop}
              onImageChange={updateDetail('avatar')}
              onCropChange={updateDetail('avatar_crop')}
              loading={loading}
              setLoading={setLoading}/>
          </div>
          <div className="form-field-container">
            <div className="form-field">
              <input
                name="firstName"
                placeholder="First Name"
                value={details.first_name}
                onChange={updateDetail('first_name')}
              />
              <input
                name="lastName"
                placeholder="Last Name"
                value={details.last_name}
                onChange={updateDetail('last_name')}
              />
            </div>
            <div className="form-field">
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                required
                value={details.phone_number}
                onChange={updateDetail('phone_number')}
              />
              <input
                name="title"
                placeholder="Title"
                value={details.title}
                onChange={updateDetail('title')}
              />
            </div>
          </div>
          {isCommunityRep && <EmailSubscriptions details={details} setDetails={setDetails} />}
          <div className="button-container">
            <button type="submit" disabled={!isValid || loading}>
              Update Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
