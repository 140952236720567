import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player'

const CALL_LEARN_MORE_PAGE = `${window.location.origin}/learn-more`;
const CALL_CONTACT_MIMI_ADDRESS = 'connect@mimicaremail.com';
const SUBJECT = 'New tool to help connect with referral partners - Mimi Connect';
const CALL_PHONE_CALL_SUBJECT = "Mimi Connect Intro Call";
const CALL_EMAIL_BODY = encodeURIComponent(`Hello,\n \n I'd like to share a new tool that helps us connect better with our closest referral partners.\n \n Mimi Connect, saves us time communicating and sharing information with referral agencies and the families they work with.\n \n Check out the link below for more details and to schedule a call with the Mimi team.\n \n Learn More About Mimi Connect: ${CALL_LEARN_MORE_PAGE}\n \n Regards,`);
const CALL_PHONE_CALL_EMAIL_BODY = encodeURIComponent(`Hello,\n \nI'd like to schedule a call to learn more about Mimi Connect.\n \n[PLEASE COMPLETE THE FOLLOWING]\n \n* Your name and phone number\n \n* Community name and max number of residents\n \n* Preferred day and time for call\n \nRegards,\n \n`);

const AGENT_VIDEO_URL = 'https://mimi-connect-prd.s3.us-west-1.amazonaws.com/Agents_Learn_More_v0.mp4';
const COMMUNITY_VIDEO_URL = 'https://mimi-connect-prd.s3.us-west-1.amazonaws.com/Communities_Learn_More_v0.mp4';

export default function InfoPage() {
  const user = useSelector((state) => state.user);

  const openEmailClientForPhoneCall = () => {
    window.open(`mailto:${CALL_CONTACT_MIMI_ADDRESS}?subject=${CALL_PHONE_CALL_SUBJECT}&body=${CALL_PHONE_CALL_EMAIL_BODY}`);
  }

  return (
    <div className='background'>
      <div className='info-page-container'>
        <h2>Mimi Connect</h2>
        
        <div className="info-page-video">
          <ReactPlayer 
            className="react-player" 
            url={ _.get(user, 'community_rep?') ? COMMUNITY_VIDEO_URL :  AGENT_VIDEO_URL}
            width='100%'
            height='100%'
            controls={ true }
          />
        </div>

        <div className='button-container'>
          <button onClick={() => openEmailClientForPhoneCall()}>Schedule Call with Mimi</button>
          <a href={`mailto:?subject=${SUBJECT}&body=${CALL_EMAIL_BODY}`}>Share With Colleague</a>
        </div>

        <div className='description-container'>
          <p className='info-page-description'>Hundreds of agents use Mimi Connect every day to search for communities best fit for their clients.</p>
          <p className='info-page-description'>Senior living communities use Mimi Connect to save time communicating and sharing information with local agents.</p>
        </div>

        { false && 
          <>
            <div>
              <div className='info-caption-title'>
                <i className="icon-phone" aria-label="contact" />
                <p className='info-caption'>Contact Management</p>
              </div>
              <p>Keep contact info up-to-date so agents can reach you when they have questions.</p>
            </div>
            <div>
              <div className='info-caption-title'>
                <i className="icon-building" aria-label="contact" />
                <p className='info-caption'>Community Profiles</p>
              </div>
              <p>Share photos, descriptions and more with agents and families they work with.</p>
            </div>
            <div>
              <div className='info-caption-title'>
                <i className="icon-clock" aria-label="contact" />
                <p className='info-caption'>Weekly Updates</p>
              </div>
              <p>Share availability, specials and more with agents near you and get notified when your update has been viewed.</p>
            </div>
            <div>
              <div className='info-caption-title'>
                <i className="icon-dollar" aria-label="contact" />
                <p className='info-caption'>Pricing</p>
              </div>
              <p>Mimi Connect is currently free during beta testing.</p>
            </div>
            <p>Communities can always use Mimi Connect for free, though some features will be available for a small monthly fee in the future.</p>
            <div className='button-container'>
              <button onClick={() => openEmailClientForPhoneCall()}>Schedule Call with Mimi</button>
              <a href={`mailto:?subject=${SUBJECT}&body=${CALL_EMAIL_BODY}`}>Share With Colleague</a>
            </div>
          </>
        }
      </div>
    </div>
  );
}
