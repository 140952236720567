import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { isLoaded } from 'google_services';

import Point from 'helpers/point';
import { communityMarker } from 'helpers/mapMarkers';
import { isResidential } from 'helpers/communities';
import { useInterval } from 'helpers/timerHooks';
import { setSelectedCommunity } from 'actions/search';
import CommunityCard from 'components/communities/CommunityCard';
import CommunityActions from 'components/communities/CommunityActions';
import RequestableStatus from 'components/communities/RequestableStatus';
import searchPin from 'images/search-pin.png';

const CONTAINER_STYLE = {
  width: '100%',
  height: '100%'
};
const MAP_OPTIONS = {
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: true,
  zoomControlOptions: {
   position: 5,
  }
};

const DEFAULT_LOCATION = new Point(39.7392, -104.9903);
const DEFAULT_ZOOM = 9;
const SELECTED_ZOOM = 12;

function MapDisplay({communities=[], userLocation, hoveredItem, setSelectedSearchCommunity}) {
  const dispatch = useDispatch();

  const {selectedCommunityId, location} = useSelector((state) => state.search);
  const selectedCommunity = _.find(communities, {id: selectedCommunityId});

  const searchLocation = Point.select(location, userLocation, DEFAULT_LOCATION);
  const centerLocation = selectedCommunity ? new Point(selectedCommunity) : searchLocation;

  const [map, setMap] = useState();
  useEffect(() => {
    if (selectedCommunityId) {
      map?.setZoom(SELECTED_ZOOM);
      map?.panTo(centerLocation);
    }
    setSelectedSearchCommunity(selectedCommunity)
  }, [selectedCommunityId]);
  useEffect(() => {
    map?.setZoom(location ? SELECTED_ZOOM : DEFAULT_ZOOM)
    map?.panTo(searchLocation);
  }, [map, searchLocation.lat]);

  const [, updateState] = useState();
  const [delay, setDelay] = useState(1000);

  useInterval(() => {
    if (isLoaded()) {
      setDelay(null);
    } else {
      updateState({});
    }
  }, delay);
  if (!isLoaded()) {
    return <div className="map-container"/>;
  }

  return (
    <div className="map-container">
      <GoogleMap
        id="community-map"
        mapContainerStyle={CONTAINER_STYLE}
        onLoad={(map) => setMap(map)}
        options={MAP_OPTIONS}
      >
        <Marker
          title="Search Location"
          position={searchLocation}
          icon={{url: searchPin, size: {width: 22, height:41}, scaledSize: {width: 22, height: 41}}}
          clickable={false}/>

        {_.map(communities, (community) => {
          const isSelected = community.id === (hoveredItem?.id || selectedCommunity?.id);
          const communityPoint = new Point(community);
          return communityPoint.isPresent() && (
            <Marker
              key={community.id}
              position={communityPoint}
              onClick={() => dispatch(setSelectedCommunity(community))}
              icon={communityMarker(isResidential(community), isSelected)}
              title={isResidential(community) ? 'residential' : 'commercial'}
              zIndex={isSelected ? 100000 + community.id : community.id}
            />
          );
        })}
      </GoogleMap>

      {!!selectedCommunity && (
        <CommunityCard
          community={selectedCommunity}
          show_company_info
          extra={
            <button className="pseudo deselect-button"
              onClick={() => dispatch(setSelectedCommunity({id: null}, false))}
              aria-label="Close"
            >×</button>
          }
        >
          {userLocation.distanceTo(new Point(selectedCommunity)) < 50 && (
            <CommunityActions community={selectedCommunity}/>
          )}
          <RequestableStatus community={selectedCommunity}/>
        </CommunityCard>
      )}
    </div>
  );
}

export default React.memo(MapDisplay)
