import { useState } from 'react';
import { maps } from 'google_services';
import { getAutocompleteResults } from 'helpers/google';
import Downshift from 'downshift';

export default function AddressAutocompleteInput({initialValue, onChange, placeholder, setLoading=_.noop}) {
  const [results, setResults] = useState([]);

  const handleStateChange = async (changes) => {
    if (changes.type === Downshift.stateChangeTypes.changeInput) {
      const searchInput = changes.inputValue.trim();
      if (searchInput.length > 2) {
        setResults(await getAutocompleteResults(searchInput));
      } else {
        onChange({address: '', city: '', state: '', zip_code: '', latitude: '', longitude: ''});
      }
    }
  };
  const setPlace = async (place) => {
    setLoading(true);
    const geocoder = new (await maps()).Geocoder();
    geocoder.geocode({placeId: place.place_id}, ([result]) => {
      const {address_components} = result;
      const getComponent = (type) => _.find(address_components, {types: [type]})?.short_name;
      onChange({
        address: [getComponent('street_number'), getComponent('route')].join(" "),
        city: getComponent('locality'),
        state: getComponent('administrative_area_level_1'),
        zip_code: getComponent('postal_code'),
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng()
      });
      setLoading(false);
    });
  }

  return (
    <Downshift
      initialInputValue={initialValue}
      onStateChange={handleStateChange}
      onChange={setPlace}
      itemToString={(item) => (item ? item.description : '')}
    >
      {({getInputProps, getMenuProps, getItemProps, isOpen}) => (
        <div className="autocomplete-widget">
          <input placeholder={placeholder} {...getInputProps()}/>
          {isOpen && (
            <ul {...getMenuProps()}>
              {results.map((result) => (
                <li {...getItemProps({item: result, key: result.description})}>
                  {result.description}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  );
}
