import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { preventDefault } from 'helpers/events';
import useErrors, { Errors } from 'helpers/errors';
import AddressAutocompleteInput from 'components/shared/AddressAutocompleteInput';
import { updateCommunities } from 'actions/communities';
import { COMMERCIAL_CARE_LEVELS } from 'helpers/communities';

const REQUIRED_FIELDS = ['name', 'address', 'city', 'state', 'zip_code'];
const trimRequired = (profile) => _.mapValues(_.pick(profile, REQUIRED_FIELDS), _.trim);
const normalizeDetails = (communities) => communities.map((community) => ({
  ..._.pick(community, 'id', 'care_capacities'),
  ...trimRequired(community)
}));

const fullAddress = (community) => _.join(_.compact([community.address, community.city, community.state, community.zip_code]), ', ');

const requiredKeysPresent = (community, addErrors) => {
  _.each(['name', 'address'], (key) => {
    if (_.trim(community[key]) === '') {
      addErrors(key, "must be present");
    }
  })
}

const allCareCapacitiesGreaterThan = (community, addErrors) => {
  _.each(community.care_capacities, (capacity, key) => {
    if (_.toNumber(capacity) < 1) {
      addErrors(`care_capacities ${key}`, "must not be zero or negative");
    }
  });
}

const validateAll = (validators) => (communities, addErrors) => {
  _.each(communities, (communities) => {
    _.each(validators, (validator) => {
      const prependAddErrors = (key, value) => addErrors(`${communities.name} ${key}`, value);
      validator(communities, prependAddErrors);
    });
  });
};

const HUMAN_CARE_LEVELS = {
  "care_capacities mc": "Maximum Memory Care Capacity",
  "care_capacities al": "Maximum Assisted Living Capacity",
  "care_capacities il": "Maximum Independent Living Capacity",
  "care_capacities sn": "Maximum Skilled Nursing Capacity",
  "care_capacities res": "Maximum Resident Capacity",
}
const humanCareLevels = (errors) => {
  return _.map(errors, ([key, value]) => {
    const updatedKey = _.reduce(HUMAN_CARE_LEVELS, (newKey, newCareLevel, careLevelKey) => {
      return _.replace(newKey, careLevelKey, newCareLevel);
    }, key);
    return [updatedKey, value];
  });
};

export default function CommunitiesListForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const communities = useSelector((state) => state.communities);
  const [details, setDetails] = useState(normalizeDetails(communities));
  const communitiesMissingDetails = _.some(communities, 'missing_info?');
  const [loading, setLoading] = useState(false);

  const [errors, valid] = useErrors([
    validateAll([requiredKeysPresent, allCareCapacitiesGreaterThan])
  ]);
  const _invalidField = (errors) => (...args) => _.some(errors, ([key]) => key === args.join(' '));

  useEffect(() => {
    setDetails(normalizeDetails(communities));
  }, [communities]);

  const eventValue = (e) => e.target.value;

  const setIndex = (index) => (changeset) => {
    setDetails((details) => details.map((detail, i) => i !== index ? detail : {
      ...detail,
      ...(_.isFunction(changeset) ? changeset(detail) : changeset)
    }));
  }

  const setValue = (index, field, getValue=eventValue) => (event) => {
    setIndex(index)((detail) => ({[field]: getValue(event, detail[field])}));
  };

  const careValue = (careKey) => ({target: {value, checked}}, care_capacities) => {
    if (_.includes(['residential', 'commercial'], value)) {
      return value === 'residential' ? {res: null} : {};
    }
    else if (checked) return {...care_capacities, [careKey]: null};
    else return _.omit(care_capacities, careKey);
  }

  const capacityValue = (careKey) => (e, care_capacities) => {
    return {...care_capacities, [careKey]: e.target.value};
  }

  const saveCommunities = async () => {
    if (!valid(details)) {
      return;
    }
    await dispatch(updateCommunities({communities: details}));
    navigate('/', {state: {success: "Profile Updated"}});
  }

  return (
    <div className="page-container">
      {communitiesMissingDetails && <div className="card hint-card">
        <span className="hint-caption"><i className='icon-lightbulb'></i>Let's Get Started</span>
        <span className="hint-text">Verify your community details.</span>
      </div>}
      <div className="card form-centered">
        <form onSubmit={preventDefault(saveCommunities)}>
          <h2>Enter Your Community Details</h2>
          <h5>Verify community name, address, care levels, and maximum resident capacities.</h5>
          {details.map((community, index) => (
            <div key={index} className="form-container">
              <h5>{community.name}</h5>
              <input
                placeholder="Community Name"
                className="input-community-name"
                value={community.name}
                onChange={setValue(index, "name")}
              />
              <AddressAutocompleteInput
                placeholder="Address"
                initialValue={fullAddress(community)}
                setLoading={setLoading}
                onChange={setIndex(index)}/>
              <div className="community-type-container">
                <label className="radio-label">
                  <input id="residential"
                    type="radio"
                    name={`residentialoptions${index}`}
                    value="residential"
                    checked={_.has(_.get(community, 'care_capacities'), 'res')}
                    onChange={setValue(index, "care_capacities", careValue())}
                  />
                  <span className="checkable">
                    <i className="icon-home" title="Residential" aria-label="residential"/>
                    Residential
                  </span>
                </label>
                <label className="radio-label">
                  <input id="commercial"
                    type="radio"
                    name={`residentialoptions${index}`}
                    value="commercial"
                    checked={!_.has(_.get(community, 'care_capacities'), 'res')}
                    onChange={setValue(index, "care_capacities", careValue())}
                  />
                  <span className="checkable">
                    <i className="icon-building" title="Commercial" aria-label="commercial"/>
                    Commercial
                  </span>
                </label>
              </div>
              {_.has(_.get(community, 'care_capacities'), 'res') ? (
                <div>
                  <label className="group-label">Maximum Resident Capacity (numbers only)</label>
                  <div className="capacity-container">
                    <input
                      type="number"
                      className={_.includes(_.keys(community.care_capacities), "res") ? "needs-info" : ""}
                      placeholder="Capacity"
                      value={_.get(community, ['care_capacities', "res"]) || ''}
                      onChange={setValue(index, "care_capacities", capacityValue("res"))}
                    />
                  </div>
                </div>
              ) : <>
                <label className="group-label">Care Levels</label>
                <div className="capacity-container">
                {_.map(COMMERCIAL_CARE_LEVELS, (careLevel, careKey) => (
                  <label className="checkbox-row" key={careKey}>
                    <input
                      type="checkbox"
                      value={careLevel}
                      checked={_.includes(_.keys(community.care_capacities), careKey)}
                      onChange={setValue(index, "care_capacities", careValue(careKey))}
                      />
                    <span className="checkable">{careLevel}</span>
                  </label>
                ))}
                </div>
                <div>
                  <label className="group-label">Maximum Resident Capacity (numbers only)</label>
                  <div className="capacity-container">
                    {_.map(COMMERCIAL_CARE_LEVELS, (careLevelCapacity, careKey) => (
                      <input
                      key={careKey}
                      type="number"
                      className={_.includes(_.keys(community.care_capacities), careKey) ? "needs-info" : ""}
                      placeholder={careLevelCapacity}
                      value={_.get(community, ['care_capacities', careKey]) || ''}
                      onChange={setValue(index, "care_capacities", capacityValue(careKey))}
                      />
                    ))}
                  </div>
                </div>
              </>}
            </div>
          ))}
          <Errors errors={humanCareLevels(errors)} />
          <div className="button-container">
            <button disabled={loading}>Update Profile</button>
          </div>
        </form>
      </div>
    </div>
  );
}
