import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import pluralize from 'pluralize';
import CommunityCard from '../communities/CommunityCard';
import ViewerCard from './ViewerCard';
import Loading from 'components/shared/Loading';
import FollowerInput from './FollowerInput';
import { removeFollower as removeFollowerAction} from '../../actions/communities';

export default function CommunityFollowerList() {
  const {id} = useParams();
  const dispatch = useDispatch();

  const community = useSelector((state) => _.find(state.communities, {id: Number(id)}));
  const followers = community?.followers;

  if (!followers || !community) {
    return <Loading />;
  }

  const removeFollower = (id, followerId) => (_e) => {
    dispatch(removeFollowerAction({id, followerId}));
  }

  return (
    <div className="page-container">
      <div className="card status-header">
        <Link to="/" className="status-view-link follower-link">View & Manage Communities</Link>
        <FollowerInput community={community}/>
      </div>
      <CommunityCard community={community} extra={
        <p className="views-text follow-text">
          {pluralize("followers", community.followers.length, true)}
        </p>
      }>
      </CommunityCard>
      {_.map(followers, (follower) => (
        <ViewerCard key={follower.email} viewer={follower} onRemove={removeFollower(community.id, follower.id)}/>
      ))}
    </div>
  );
};

