import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { loadStatuses } from 'actions/statuses';
import CommunityCard from '../communities/CommunityCard';
import Status from '../communities/Status';
import ViewerCard from './ViewerCard';
import pluralize from 'pluralize';
import Loading from 'components/shared/Loading';

export default function StatusDetail() {
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStatuses());
  }, []);

  const status = useSelector(({statuses}) => _.find(statuses, {id: Number(id)}));
  const community = useSelector(({communities}) => _.find(communities, {id: status?.community_id}));

  if (!status || !community) {
    return <Loading />;
  }

  return (
    <div className="page-container">
      <div className="card status-header">
        <Link to="/" className="status-view-link">View & Manage Communities</Link>
        <Link to="/statuses" className="status-view-link">View All Updates</Link>
      </div>
      <CommunityCard community={community} extra={<p className="views-text">{pluralize("Views", status?.views, true)}</p>}>
        <div className="card-section">
          <Status status={status}/>
        </div>
      </CommunityCard>
      {_.reverse(status.viewed_by_agents)?.map((viewer) => (
        <ViewerCard key={viewer.id} viewer={viewer}/>
      ))}
    </div>
  );
}
