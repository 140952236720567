const presentPoint = (p) => p instanceof Point && p.isPresent();

const setPoint = (...args) => {
  let lat = null, lng = null;

  if (args.length == 2) {
    [lat, lng] = args;
  } else if (args[0] instanceof Array) {
    [lat, lng] = args[0]
  } else if (args[0] instanceof Object) {
    ({lat, lng} = args[0]);
    ({latitude:lat=lat, longitude:lng=lng} = args[0]);
    if (lat instanceof Function) {
      lat = lat(), lng = lng();
    }
  }

  return [lat, lng];
}

const mToMi = (meters) => meters * 0.000621371;

export default class Point {
  constructor() {
    [this.lat, this.lng] = setPoint(...arguments);
  }
  
  get latitude() { return this.getLat(); }
  get longitude() { return this.getLng(); }
  
  array = () => [this.getLat(), this.getLng()]
  isEmpty = () => !this.lat || !this.lng
  isPresent = () => this.lat && this.lng
  eq = (point) => this.lat === point.lat && this.lng === point.lng
  toG = () => ({lat: this.lat, lng: this.lng})
  distanceTo = (point) => {
    if (!window.google) return;
    return mToMi(window.google.maps.geometry.spherical.computeDistanceBetween(this, point));
  }

  static select(...points) {
    return points.find(presentPoint) || NullPoint
  }

  getLat = () => this.lat
  getLng = () => this.lng
}

export const NullPoint = new Point();

// Usage:
// - new Point(lat, lng)
// - new Point({lat, lng})
// - new Point({latitude, longitude})
// - centerpoint = (new Point({lat, lng}).array())
// - locationDetails = {
//     ...location,
//     ...(new Point(lat, lng))
//   }
// Point.select(point1, point2, point3)
