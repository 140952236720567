import ProfileImage from 'helpers/ProfileImage';

export default function ViewerCard({viewer, onRemove}) {

  return (
    <div className="card viewer-details">
      <div className="card-header">
        <ProfileImage user={viewer}/>
        {viewer.full_name ? (
          <div className="card-details">
            <h3 className="name">{viewer.full_name}</h3>
            <p>{viewer.title}</p>
            <p>{viewer.company_name}</p>
          </div>
          ) : (
          <div className="card-details">
            <h3 className="name">{viewer.email}</h3>
            <p className="pending">Added by {viewer.invited_by_full_name}</p>
            {onRemove && <button type="button" className="remove-button" onClick={onRemove}>× Remove Follower</button>}
          </div>
        )}
      </div>
    </div>
  );
};
