export function statusViewed(status) {
  return async (dispatch, getState, api) => {
    dispatch({type: 'status/viewed', status});
    try {
      await api.post('metrics', {metric: {event_type: 'view', object_type: 'Status', object_id: status.id}})
    } catch (e) {
      (window.Rollbar || console).warn(e);
    }
  }
}

export function messageViewed(message) {
  return async (dispatch, getState, api) => {
    dispatch({type: 'message/viewed', message});
    try {
      await api.post('metrics', {metric: {event_type: 'view', object_type: 'Message', object_id: message.id}})
    } catch (e) {
      (window.Rollbar || console).warn(e);
    }
  }
}
