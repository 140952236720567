import qs from 'qs';

export function startChat(communityID, enterConversation) {
  return async (dispatch, getState, api) => {
    const {data:conversation} = await api.post('/conversations', {'community_id': communityID});
    enterConversation(conversation);
    return dispatch({type: 'conversation/started', conversation});
  }
}

export function loadConversations() {
  return async (dispatch, getState, api) => {
    const {data:conversations} = await api.get('conversations');
    return dispatch({type: 'conversations/received', conversations});
  }
}

export function addMessage(id, message, action) {
  const type = action === 'created' ? 'conversation/addMessage' : 'conversation/updateMessage';
  return {type, id, message};
}

export function loadConversation(id) {
  return async (dispatch, getState, api) => {
    const {data:conversation} = await api.get(`conversations/${id}`);
    return dispatch({type: 'conversation/started', conversation});
  }
}

export function loadMessages(conversation_id, limit, before) {
  return async (dispatch, getState, api) => {
    const {data:messages} = await api.get(`conversations/${conversation_id}/messages?${qs.stringify({limit, before})}`);
    return dispatch({type: 'conversation/addMessages', id: conversation_id, messages});
  }
}
