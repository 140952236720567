import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { loadCommunityStatuses } from 'actions/statuses';
import Timestamp from 'helpers/Timestamp';
import CommunityCard from 'components/communities/CommunityCard';
import Loading from 'components/shared/Loading';

const sortStatuses = (statuses) => _.reverse(_.sortBy(statuses, 'created_at'));
const getStatuses = (community_id) => (state) => sortStatuses(_.filter(state.statuses, {community_id}));

export default function CommunityStatusHistory() {
  const communityId = Number(useParams().id);
  const community = useSelector((state) => _.find(state.communities, {id: communityId}));
  const statuses = useSelector(getStatuses(communityId));
  const [loaded, setLoaded] = useState(!!statuses.length);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCommunityStatuses(communityId)).then(() => setLoaded(true));
  }, []);

  if (!community) return <Loading/>

  return (
    <div className="page-container">
      <CommunityCard community={community}>
        <div className="card-section">
          <h3>Status Update History</h3>
          {loaded && !statuses.length && (
            <div className="community-status">This community has not posted any status updates yet.</div>
          )}
          {statuses.map((status) => (
            <div key={status.id} className="community-status">
              <Timestamp dateTime={status.created_at} format="dddd, MMM D h:mm A"/>
              <div className="status-view-container">
                <p>{status.content}</p>
              </div>
            </div>
          ))}
        </div>
      </CommunityCard>
    </div>
  );
}
