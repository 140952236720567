import React from 'react';

export default function AccessDenied() {
  return (
    <div className="page-container">
      <div className="card">
        <h2 className="centered">You must be registered to view this page</h2>
        <p className="centered"> For more information, or to register, please contact the Mimi team.</p>
      </div>
    </div>
  )
}
