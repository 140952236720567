import resSelectedPin from 'images/res-selected-pin.png';
import resPin from 'images/res-pin.png';
import comSelectedPin from 'images/com-selected-pin.png';
import comPin from 'images/com-pin.png';

export function communityMarker(residental, selected) {
  const url = residental ?
    (selected ? resSelectedPin : resPin) :
    (selected ? comSelectedPin : comPin);
  const size = selected ?
    {width: 26, height: 49} :
    {width: 22, height: 41};
  return {
    url,
    size,
    scaledSize: size
  }
}
