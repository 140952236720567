import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Downshift from 'downshift';
import Fuse from 'fuse.js';

import Point from 'helpers/point';
import { HighlightText } from 'helpers/text';
import { setSelectedCommunityID, setSelectedLocation, clearSelectedLocation } from 'actions/search';
import { searchCommunities, loadCommunities, loadCommunitiesNearLocation } from 'actions/communities';
import { CareLevelIcon } from 'components/communities/CommunityImage';

const extractItemWithScore = (radius) => ({item, score}) => {
  const defaultSearchRadius = 50;
  radius = radius && _.isNumber(radius) ? radius : defaultSearchRadius;
  const distanceScore = item.distance / radius;
  return {...item, score: _.mean([distanceScore, score])};
}

const sortCommunities = (communities) => _.sortBy(communities, ['score']);
const normalizeSearch = (results, radius) => _.map(results, extractItemWithScore(radius));
const sortAndNormalizeCommunityResults = (results, radius) => {
  return sortCommunities(normalizeSearch(results, radius));
}

const ChatIcon = ({conversation}) => (
  conversation ? (
    <Link to={`/conversations/${conversation.id}`}
      className="conversation-link"
      title="go to existing conversation"
    >
      <i className="icon-chat" aria-label="go to existing conversation"/>
    </Link>
  ) : null
);

const communitySearch = (fuse, searchString, communities, radius) => {
  if (searchString === '') return communities;
  const exactResults = fuse.search(`'"${searchString}"`);
  const exactNResults = sortAndNormalizeCommunityResults(exactResults, radius);

  const fuzzyResults = fuse.search(`"${searchString}"`);
  const fuzzyNResults = sortAndNormalizeCommunityResults(fuzzyResults, radius);

  return _.unionBy(exactNResults, fuzzyNResults, 'name');
}

function SearchBox() {
  const communities = useSelector((state) => state.communities);
  const user = useSelector((state) => state.user);
  const conversations = useSelector((state) => state.conversations);
  const findConversation = (community_id) => _.find(conversations, {community_id});
  const search = useSelector((state) => state.search);

  const [searchString, setSearchString] = React.useState('');
  const dispatch = useDispatch();

  const searchElement = useRef(null);

  const searchForLocation = _.throttle((location) => {
    dispatch(searchCommunities(location));
  }, 1000, {leading: true, trailing: true})

  const clearSearchBox = () => {
    if (search.location) {
      dispatch(clearSelectedLocation());
    } else {
      dispatch(setSelectedCommunityID(null, false));
    }
  }

  useEffect(() => {
    if (searchString.trim() === "") clearSearchBox();
  }, [searchString]);

  const handleStateChange = (changes) => {
    if (changes.selectedItem) {
      setSearchString(changes.selectedItem.name);

      if (changes.selectedItem.id) {
        dispatch(setSelectedCommunityID(changes.selectedItem.id, true));
      } else {
        dispatch(setSelectedLocation(changes.selectedItem));
      }

      searchElement.current.blur();
    } else if (changes.type === Downshift.stateChangeTypes.changeInput) {
      const searchInput = changes.inputValue.trim();
      setSearchString(searchInput);

      if (searchInput.length > 2) {
        searchForLocation(searchInput);
      }
    }
  };

  const fuse = useMemo(() => new Fuse(communities, {
    keys: ['name', 'full_address'],
    includeScore: true,
    minMatchCharLength: 2,
    useExtendedSearch: true,
    shouldSort: false
  }), [communities]);

  const predictedCommunities = useMemo(() => {
    return communitySearch(fuse, searchString, communities, user?.search_radius);
  }, [searchString, communities]);

  const preventMouseUpStateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.mouseUp:
        return {
          ...changes,
          searchString: state.searchString,
        }
      default:
        return changes;
    }
  };

  const predictedPlaceItem = {
    ...search?.predictedPlace,
    name: search?.predictedPlace?.description
  };

  return (
    <Downshift
      stateReducer={preventMouseUpStateReducer}
      onStateChange={handleStateChange}
      itemToString={(item) => (item ? item.name : '')}
    >
      {({
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        openMenu,
        selectItem,
        selectedItem
      }) => (
        <div className="search-container">
          <label {...getLabelProps({className: "sr-only"})}>
            Search Communities
          </label>
          <div className="search-input">
            <input
              ref={searchElement}
              value={searchString}
              {...getInputProps({
                className: isOpen ? 'menu-open' : null,
                placeholder: 'Search Communities...',
                "aria-label": 'Search Communities By Name and Address'
              })}
              onFocus={() => openMenu()}
            />
            {(searchString || selectedItem) && (
              <button className="clear-search"
                aria-label="clear search"
                onClick={() => {
                  setSearchString('');
                  selectItem(null);
                }}
              >
                ×
              </button>
            )}
          </div>
          <div className="search-menu">
            <ul
              {...getMenuProps({
                className: isOpen ? null : 'menu-closed'
              })}
            >
              {isOpen && search.predictedPlace && (
                <li {...getItemProps({
                  item: predictedPlaceItem,
                  className: "search-option",
                  index: predictedCommunities.length
                })} >
                  <i className="icon-search facility-type" aria-label="predicted-place" />
                  <div className="place-address">
                    {search.predictedPlace.description}
                  </div>
                  <div className="community-address">
                    <p className="search-option-description">Search communities by location</p>
                  </div>
                </li>
              )}
              {isOpen && predictedCommunities.map((item, index) => (
                <li key={index} {...getItemProps({item, index, className: 'search-option'})}>
                  <CareLevelIcon careLevels={item.care_levels}/>
                  <div className="community-name">
                    <HighlightText highlight={searchString} text={item.name}/>
                  </div>
                  <div className="community-address">
                    <HighlightText highlight={searchString} text={item.full_address}/>
                  </div>
                  <ChatIcon conversation={findConversation(item.id)}/>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Downshift>
  )
}

export default React.memo(SearchBox)
