import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { inviteFollower } from "../../actions/communities";
import classnames from 'classnames';

import FlashMessage from "../shared/FlashMessage";

const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASTED_EMAIL_REGEXP = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g;

export default function FollowerInput({community}) {
  const [emails, setEmails] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isInList = (email) => {
    return emails.includes(email);
  };

  const isEmail = EMAIL_REGEXP.test.bind(EMAIL_REGEXP);

  const isValid = (email) => {
    setError(null);

    if (isInList(email)) {
      setError(`${email} has already been added.`);
      return false;
    };

    if (!isEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return false;
    };

    return true;
  };

  const displayMessage = () => {
    navigate('.', {state: {success: `You have successfully added followers. Duplicate emails have been removed.`}})
  }

  const addFollowers = () => {
    if(loading) return;
    setLoading(true);

    _.each(emails, (email) => {
      dispatch(inviteFollower({id: community.id, email}));
    });

    setEmails([]);
    setValue('');
    setError(null);
    setLoading(false);
    displayMessage();
  };

  const updateEmailValue = (value="") => {
    value = value.trim();
    setValue(value);
    if (value && isValid(value)) {
      setEmails([...emails, value]);
      setValue("");
    }
  };

  const captureHotKeys = (e) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      updateEmailValue(value);
    };
  };

  const addEmail = (e) => {
    e.preventDefault();
    updateEmailValue(value);
  }

  const updateEmail = (e) => {
    setValue(e.target.value);
    setError(null);
  };

  const deleteEntry = (email) => (_e) => {
    setEmails(emails.filter(item => item !== email));
  };

  const pasteItem = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    const pastedEmails = paste.match(PASTED_EMAIL_REGEXP);

    if (pastedEmails) {
      setEmails(_.uniq([...emails, ...pastedEmails]));
    };
  };
  
  return (
    <div className="add-follower-card">
      <FlashMessage />
      <h5>Add Followers</h5>
      <p>Instantly notify referral sources when you update this community.</p>
      <div className="input-container">
        <input
          className={classnames("input", {"has-error": error})}
          value={value}
          placeholder="Type or paste email addresses separated by commas (,) or press ENTER"
          onKeyDown={captureHotKeys}
          onChange={updateEmail}
          onPaste={pasteItem}
        />
        <button className="secondary" disabled={_.isEmpty(value)} onClick={addEmail}>+ Add</button>
      </div>
      <div className="email-display">
        {emails.map(email => (
          <div className="email-item" key={email}>
            {email}
            <button type="button" className="clear" onClick={deleteEntry(email)}>
              &times;
            </button>
          </div>
        ))}
      </div>
      <button className="secondary add-button" hidden={_.isEmpty(emails)} onClick={addFollowers}>Add All Followers</button>
      {error && <p className="error warning">{error}</p>}
    </div>
  );
};
