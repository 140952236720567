import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadCompany } from 'actions/companies';

import Loading from 'components/shared/Loading';
import CompanyCard from "components/companies/CompanyCard";


export default function CompanyPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies);
  const company = _.find(companies, {id: Number(id)});

  useEffect(() => {
    if (!company) {
      dispatch(loadCompany(id))
    }
  }, [])

  if (!company) return <Loading />;

  return (
    <div className="page-container">
      <CompanyCard company={company} />
    </div>
  )
}
