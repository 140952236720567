import { useDispatch } from 'react-redux';
import Status from 'components/communities/Status';
import { enterConversation } from 'helpers/enterConversation';

export default function RequestableStatus({community}) {
  const dispatch = useDispatch();

  return community.status ? (
    <Status status={community.status}/>
  ) : (
    <div className="community-status">
      <p className="info">No update available</p>
      <button type="button" className="pseudo" onClick={() => enterConversation(community.id, true, dispatch)}>
        Request Availability + More
      </button>
    </div>
  );
}
