import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { stopPropagation } from 'helpers/events';
import { signOut } from 'actions/user';
import ProfileImage from 'helpers/ProfileImage';
import FormLink from '../helpers/FormLink';

export default function MenuDrawer({open, setOpen, user}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const close = () => setOpen(false);
  const logOut = async () => {
    await dispatch(signOut());
    navigate('/');
  };

  console.log('user = ', user)

  return (
    <div className={classnames("menu-container", {open})} onClick={close}>
      <div className="menu-drawer" onClick={stopPropagation()}>
        <button className="menu-close pseudo" onClick={close}>&times;</button>
        <div className="menu-content">
          <ProfileImage user={user}/>
          <h3 className="name">{user?.full_name}</h3>
          <p>{user?.title}</p>
          <p>{user?.company_name}</p>
          <Link to="/user/edit" className="button secondary" onClick={close}>Edit Profile</Link>
          <FormLink className="button secondary" email={user?.email} link="support" onClick={close}>
            Get Support
          </FormLink>
          
          { (user?.agent_profile && user?.agent_profile?.external_company_id) &&
            <a href="/crm/" className="button secondary">
              Mimi CRM
            </a>
          }

        </div>
        <button onClick={logOut} title={`Log out ${user.email}`}>Log Out</button>
      </div>
    </div>
  );
}
