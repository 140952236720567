import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function FlashMessage({message:message_, hideAfter=8000}) {
  const {state} = useLocation();
  const [message, setMessage] = useState(message_ || state?.success);

  useEffect(() => {
    if (state?.success) history.replaceState(_.omit(state, 'success'), '');
    const messageTimer = setTimeout(() => setMessage(false), hideAfter);
    return () => clearTimeout(messageTimer);
  }, []);

  if (!message) return null;
  return (
    <div className="notice">
      <span className="close" onClick={() => setMessage(false)}>&times;</span>
      {message}
    </div>
  );
}
