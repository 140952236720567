import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCommunity } from 'actions/search';
import Point from 'helpers/point';
import CommunityCard from 'components/communities/CommunityCard';
import CommunityActions from 'components/communities/CommunityActions';
import RequestableStatus from 'components/communities/RequestableStatus';

export default function SearchResultsList({communities=[], userLocation, setHoveredItem, displayMap}) {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.search);
  const {agent_profile} = useSelector((state) => state.user);

  const searchMode = search.location || search.searchMode;

  const searchLocation = search.place?.description.split(',')[0];

  if (searchMode) {
    communities = _.orderBy(communities, 'distanceFromLocation');
  } else {
    communities = _.orderBy(_.filter(communities, 'status'), 'status.created_at', 'desc');
  }

  return (
    <div className="search-results-list" onMouseLeave={() => setHoveredItem(null)}>
      {!communities.length && !searchMode ? (
        <p className="info">Type in the search bar above to view community information and updates.</p>
      ):(
        <div className="info">
          <p>{searchMode ? 'Communities' : 'Community Updates'} Near {searchLocation || agent_profile?.address}</p>
          <p>Sorted By {searchMode ? 'Distance' : 'Post Date'}</p>
        </div>
      )}
      {communities.map((community) => (
        <CommunityCard key={community.id}
          community={community}
          show_company_info
          onMouseEnter={() => setHoveredItem(community)}
          onClick={() => {
            dispatch(setSelectedCommunity(community));
            displayMap();
          }}
        >
          {userLocation.distanceTo(new Point(community)) < 50 && (
            <CommunityActions community={community}/>
          )}
          <RequestableStatus community={community}/>
        </CommunityCard>
      ))}
    </div>
  );
}
