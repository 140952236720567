import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

import { loadStatuses } from 'actions/statuses';
import CommunityCard from 'components/communities/CommunityCard';
import Status from 'components/communities/Status';
import FlashMessage from 'components/shared/FlashMessage';
import InfoCard from 'components/shared/InfoCard';

const getStatuses = (state) => _.reverse(_.sortBy(state.statuses, 'created_at'));
const mergeCommunities = (selector) => (state) => selector(state).map((item) => ({
  ...item,
  community: _.find(state.communities, {id: item.community_id})
}));

export default function CommunityStatusFeed() {
  const statuses = useSelector(mergeCommunities(getStatuses));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStatuses());
  }, []);

  return (
    <div className="page-container">
      <InfoCard>
        <Link to="/" className="link-text">
          View & Manage Communities
        </Link>
      </InfoCard>
      <FlashMessage />
      {statuses.map((status) => status?.community && (
        <CommunityCard key={status.id}
          community={status.community}
          extra={status.views > 0 ? (
            <Link to={`/statuses/${status.id}`} className="community-status-link">
              <p className="views-text">{pluralize("Views", status.views, true)}</p>
            </Link>
          ) : (
            <p className="views-text">0 Views</p>
          )
        }>
          <div className="card-section">
            <Status status={status}/>
          </div>
        </CommunityCard>
      ))}
    </div>
  );
}
