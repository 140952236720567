import Point from 'helpers/point';

const calculateDistance = (location) => (community) => ({
  ...community,
  distanceFromLocation: location?.distanceTo(new Point(community)) || community.distance
})

export default function communitiesReducer(state=[], action) {
  switch (action.type) {
    case 'communities/received':
      return _.uniqBy([...action.communities, ...state], "id");
    case 'community/received':
      return _.uniqBy([action.community, ...state], "id");
    case 'communities/updated':
      return state.map((community) => (
        _.find(action.communities, {id: community.id}) || community
      ))
    case 'communities/search':
      return _.uniqBy([...state, ...action.communities], 'id').map(calculateDistance(action.location));
    case 'search/setSelectedLocation':
      return state.map(calculateDistance(action.location));
    default:
      return state.map((community) => {
        switch (action.type) {
          case 'community/follow':
            return community.id === action.id ? {...community, followed: true} : community;
          case 'community/addFollower':
            if (community.id === action.id) {
              const followers = _.uniqBy([...community.followers, action.follower], "id");
              return {...community, followers};
            }
            return community;
          case 'community/removeFollower':
            return community.id === action.id ? {...community, followers: _.reject(community.followers, {id: action.followerId})} : community;
          case 'community/unfollow':
            return community.id === action.id ? {...community, followed: false} : community;
          case 'status/viewed':
            if (community.status?.id === action.status.id) {
              return {...community, status: {...community.status, viewed: true}};
            }
            return community
          default:
            return community
        }
      });
  }
}
