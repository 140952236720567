import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function CommunityRepList({community}) {
  const [openDisplay, setOpenDisplay] = useState(false)

  return (
    <div className="card-section">
      <div className="community-contacts-display">
        <div className="collapsible" onClick={() => setOpenDisplay(!openDisplay)}>
          <i className="icon-user" aria-label="profile" />
          {_.filter(community.communities_users, "active").length} Contacts
          <i className={openDisplay ? "icon-up-dir" : "icon-down-dir"} />
        </div>
        <Link className="community-nav" to="/invite">
          <p className="views-text">+ Invite Coworkers</p>
        </Link>
      </div>
      {openDisplay &&
        <div className="community-rep-display">
          {_.filter(community.communities_users, "active").map(({id, full_name, title, email, active, pending}) => active && (
            <div className="community-rep" key={id}>
              <p className="community-rep-name">{_.trim(full_name) ? full_name : "No Name"}</p>
              <p className="community-rep-title">{title ? title : "No Title"}</p>
              <p className="community-rep-email">{email} {pending && <span className="pending">(pending)</span>}</p>
            </div>
          ))}
        </div>
      }
    </div>
  );
}
