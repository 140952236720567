export function createStatuses(statuses) {
  return async (dispatch, getState, api) => {
    return Promise.all(_.map(statuses, (content, community_id) => {
      if (!_.trim(content)) return;
      return api.post(`communities/${community_id}/statuses`, {status: {content}});
    }));
  }
}

export function loadStatuses() {
  return async (dispatch, getState, api) => {
    const {data:statuses} = await api.get('statuses');
    return dispatch({type: 'statuses/received', statuses});
  }
}

export function loadCommunityStatuses(communityId) {
  return async (dispatch, getState, api) => {
    const {data:statuses} = await api.get(`communities/${communityId}/statuses`);
    return dispatch({type: 'statuses/received', statuses});
  }
}
