import qs from 'qs';
import { setSelectedAddress } from 'actions/search';

const withQuery = (url, params={}) => {
  const oldParams = qs.parse(url.split('?')[1]);
  const query = qs.stringify({ ...oldParams, ...params});
  return _.compact([url, query]).join('?');
}

export function loadCommunities() {
  return async (dispatch, _getState, api) => {
    const {data:communities} = await api.get('communities');
    return dispatch({type: 'communities/received', communities});
  }
}

export function loadCommunity(community_id) {
  return async (dispatch, _getState, api) => {
    const {data:community} = await api.get(`communities/${community_id}`);
    return dispatch({type: 'community/received', community});
  }
}

export function loadCommunitiesNearLocation(location) {
  return async (dispatch, _getState, api) => {
    const query = qs.stringify({location: location.array()}, {arrayFormat: "brackets"});
    const {data:communities} = await api.get(`communities?${query}`);
    return dispatch({type: 'communities/search', communities, location});
  }
}

export function searchCommunities(searchString) {
  return async (dispatch, _getState, api) => {
    _.defer(async () => {
      const {data:communities} = await api.get(`communities?${qs.stringify({q: searchString})}`);
      dispatch({type: 'communities/search', communities});
    })
    dispatch(setSelectedAddress(searchString));
  }
}

export function inviteFollower({id, email}) {
  return async (dispatch, _getState, api) => {
    const response = await api.post(`communities/${id}/invited_followers`, {user: {email}});
    dispatch({type: 'community/addFollower', id, follower: response.data});
  };
}

export function followCommunity({id}) {
  return async (dispatch, _getState, api) => {
    const response = await api.put(`communities/${id}/follow`);
    dispatch({type: 'community/follow', id});
  };
}

export function removeFollower({id, followerId}) {
  return async (dispatch, _getState, api) => {
    await api.delete(`communities/${id}/invited_followers/${followerId}`);
    dispatch({type: 'community/removeFollower', followerId, id});
  };
}

export function unfollowCommunity({id}) {
  return async (dispatch, _getState, api) => {
    await api.delete(`communities/${id}/follow`);
    dispatch({type: 'community/unfollow', id});
  };
}

export function invite({email, community_ids}) {
  return async (dispatch, _getState, api) => {
    const response = await api.post('invitations', {invitation: {email, community_ids}});
    dispatch(loadCommunities());
  };
}

export function updateCommunities({communities}) {
  return async (dispatch, _getState, api) => {
    const response = await api.patch(`communities`, {communities});
    return dispatch({type: 'communities/updated', communities: response.data});
  };
}
