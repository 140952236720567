import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

import { statusViewed } from 'actions/metrics';
import Timestamp from 'helpers/Timestamp';

export default function Status({status, views}) {
  const dispatch = useDispatch();
  const {ref, inView, entry} = useInView({
    threshold: 1,
    onChange(visible) {
      if (visible && !status.viewed) dispatch(statusViewed(status));
    }
  });

  return (
    <div className="community-status" ref={ref}>
      <Timestamp dateTime={status.created_at} format="dddd, MMM D h:mm A"/>
      {views !== undefined && (
        <div className="status-view-count">
          <Link className="views-text" to={`/statuses/${status?.id}`}>
            {pluralize("Views", views, true)}
          </Link>
          <Link className="views-text" to="/statuses">
            All Statuses
          </Link>
        </div>
      )}
      <div className="status-view-container">
        <p>{status.content}</p>
      </div>
    </div>
  );
}
