const sort = (messages) => _.sortBy(_.uniqBy(messages, 'id'), 'created_at');

export default function conversationsReducer(state=[], action) {
  switch (action.type) {
    case 'conversation/started':
      const conversations = _.reject(state, {id: action.conversation.id});

      return [...conversations, action.conversation];
    case 'conversations/received':
      return _.uniqBy([...state, ...action.conversations], 'id');
    case 'conversation/addMessage':
      return state.map((conversation) => {
        if (conversation.id != action.id) return conversation;

        const message_count = conversation.message_count + 1;
        return {
          ...conversation,
          message_count,
          messages: sort([...conversation.messages, action.message]),
          last_author_id: action.message.author_id,
          updated_at: action.message.created_at
        };
      });
    case 'conversation/updateMessage':
      return state.map((conversation) => {
        if (conversation.id != action.id) return conversation;

        return {
          ...conversation,
          messages: conversation.messages.map((message) => {
            if (message.id !== action.message.id) return message;

            return {...action.message, ..._.pick(message, 'viewed')};
          })
        };
      });
    case 'conversation/addMessages':
      return state.map((conversation) => {
        if (conversation.id != action.id) return conversation;

        return {...conversation, messages: sort([...conversation.messages, ...action.messages])};
      });
    case 'user/communityProfileUpdated':
      return state.map((conversation) => {
        if (conversation.community_id != action.profile.community_id) return conversation;

        return {...conversation,
          community_reps: _.uniqBy([{...action.profile, id: action.user.id}, ...conversation.community_reps], 'id')
        };
      });
    case 'message/viewed':
      return state.map((conversation) => {
        return {...conversation, messages: _.map(conversation.messages, (message) => (
          message.id === action.message.id ? {...message, viewed: true} : message
        ))};
      })
    default:
      return state;
  }
}
