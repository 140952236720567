import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { messageViewed } from 'actions/metrics';
import Timestamp from 'helpers/Timestamp';
import ProfileImage from 'helpers/ProfileImage';

const Message = ({message, author={}, viewedBy}) => {
  const dispatch = useDispatch();
  const {ref, inView, entry} = useInView({
    threshold: 1,
    onChange(visible) {
      if (visible && !message.viewed) dispatch(messageViewed(message));
    }
  });

  return (
    <div className="message" ref={ref}>
      <div className="author-info">
        <ProfileImage user={author}/>
        <div className="author-details">
          <p className="name">{author.full_name}</p>
          <p>{author.title}</p>
          <p>{author.company_name}</p>
        </div>
      </div>
      <p>{message.content}</p>
      <div className="message-footer">
        <div className="avatar-container">
          {message.viewed && viewedBy.length > 0 && <p>Seen by:</p>}
          {!message.viewed ? <p>Not yet viewed</p> : <p></p>}
          {viewedBy.map((viewer) => <ProfileImage key={viewer.id} user={viewer}/>)}
        </div>
        <Timestamp dateTime={message.created_at}/>
      </div>
    </div>
  )
};

const Messages = ({messages, participants, user}) => {
  messages = _.sortBy(messages, 'created_at');

  return _.map(messages, (message={}) => {
    const author = _.find(participants, {id: message.author_id}) || {id: null, full_name: "User is no longer available"};
    const viewedBy = _.without(message.viewed_by, author.id, user.id).map(
      (id) => _.find(participants, {id})
    );
    return <Message key={message.id} message={message} author={author} viewedBy={viewedBy}/>;
  });
};

export default Messages;
