import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createStatuses } from 'actions/statuses';
import CommunityCard from 'components/communities/CommunityCard';

const getCommunities = (state) => _.sortBy(state.communities, 'name');
const initialStatuses = (communities) => communities.reduce((initialState, {id}) => ({...initialState, [id]: ""}), {});

export default function CommunityStatusUpdates() {
  const communities = useSelector(getCommunities);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [statuses, setStatuses] = useState(initialStatuses(communities));
  const setStatus = (id) => ({target: {value}}) => setStatuses({...statuses, [id]: value});
  const [error, setError] = useState("");

  const updateStatus = async () => {
    setError(null);
    try {
      const result = await dispatch(createStatuses(statuses));
      navigate('/statuses', {state: {success: "Statuses updated"}});
    } catch (e) {
      setError(e);
    }
  }

  return (
    <div className="page-container">
      <div className="instructions-card">
        <p>Use the input fields below to share availability, specials and more with agents near you.</p>
        <p>Mimi Connect will instantly notify you when an agent views your update.</p>
        <Link to="/" className="link-text">
          View & Manage Communities
        </Link>
      </div>
      {error && <div className="alert">{error.message}</div>}
      {communities.map(({id, ...community}) => (
        <CommunityCard key={id} community={community}>
          <div className="card-body">
            <textarea name="status"
              maxLength="500"
              rows="5"
              placeholder="Enter availability, specials and more"
              value={statuses[id]}
              onChange={setStatus(id)}/>
          </div>
        </CommunityCard>
      ))}
      <div className="button-group align-right">
        <Link to="/" className="button pseudo">Cancel</Link>
        <button type="button" onClick={updateStatus}>Post</button>
      </div>
    </div>
  );
}
