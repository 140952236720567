import React, { useState } from 'react';

const ALLOWED_KEYS = ['first_name', 'last_name', 'title'];

const titleize = (string = '') => _.kebabCase(string).split('-').map(_.capitalize).join(' ');

const normalizeDetails = (profile) => (
  _.mapValues(_.pick(profile, ALLOWED_KEYS), (value) => (
    _.isEmpty(value) ? '' : value
  ))
);

export default function ProfileForm({onSave, profile={}}) {
  const [details, setDetails] = useState(normalizeDetails(profile));
  const [messagingAllowed, setMessagingAllowed] = (useState(profile.messaging_allowed));
  const isValid = (_.every(_.map(_.values(details), _.trim))) && messagingAllowed;
  function updateDetail({target}, name) {
    setDetails((details) => ({
      ...details,
      [name]: target.value
    }));
  }

  return (
    <form className="cr-profile-form" onSubmit={(e) => onSave(e, {...details, messaging_allowed: messagingAllowed})}>
        <div className="hint-card">
          <p className="hint-caption">Message This Community</p>
          <p className="hint-text">Request availability, specials, and more instantly.</p>
        </div>

      {_.keys(details).map((key) => (
        <div className="form-field" key={key}>
          <label htmlFor={key}>{titleize(key)} {!details[key] && <span class="alert-text">Required</span>}</label>
          <input
            id={key}
            name={key}
            type="text"
            value={details[key]}
            onChange={(e) => updateDetail(e, key)}
          />
        </div>
      ))}
      {!profile['registered?'] && (
      <label className="checkbox-row">
        <input type="checkbox"
          checked={messagingAllowed}
          onChange={(e) => setMessagingAllowed(e.target.checked)}/>
        <span className="checkable">I agree to the TOS</span>
      </label>
      )}
      <button disabled={!isValid}>Update Contact</button>
    </form>
  );
};
